import axios from "axios";
import { getJWTToken } from "./auth";
import {
  commonApiUrl,
  attorneyFlowApiUrl,
  conflictManagementApiUrl,
  clientMatterInquiryApiUrl,
  accessUrl,
} from "../../config";

// export const axiosInst = axios.create({
//     headers: {
//         'Content-Type': 'application/json',
//         'Access-Control-Allow-Origin': '*',
//     }
// });

export const axiosInst = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

// Intercepts each API request and added Auth header
axiosInst.interceptors.request.use(async (request) => {
  const jwtToken = await getJWTToken();
  if (jwtToken && !request.url.includes("/token")) {
    request.headers["authorization"] = `Bearer ${jwtToken.token}`;
    request.headers["accesstoken"] = `Bearer ${jwtToken.accessToken}`;
    request.headers["x-spc-trk-channel"] = "web";
  }

  return request;
});

axiosInst.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 401) {
      window.location.href = window.location.origin;
    } else if (err.response.status === 403) {
      if (err.response.data && err.response.data.message) {
        localStorage.setItem("userRolesUpdated", "true");
      }
    }
    return err.response;
  }
);

const getModuleURL = (module) => {
  switch (module) {
    case "TM":
      return attorneyFlowApiUrl;
    case "AM":
      return accessUrl;
    case "CM":
      return conflictManagementApiUrl;
    case "CMI":
      return clientMatterInquiryApiUrl;
    case "COMMON":
      return commonApiUrl;
    default:
      return conflictManagementApiUrl;
  }
};

var CancelToken = axios.CancelToken;
const cancelRequest = {};

async function callApi(
  url,
  module,
  method,
  headers,
  body,
  responseType,
  requestName,
  cancelPrevRequests
) {
  try {
    if (
      cancelPrevRequests &&
      requestName &&
      cancelRequest[requestName] &&
      typeof cancelRequest[requestName] === "function"
    ) {
      //Cancel previous request
      cancelRequest[requestName]();
    }
    const options = {
      baseURL: getModuleURL(module),
      method,
      url,
      headers: headers,
      mode: "cors",
      responseType: responseType || "json",
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        requestName && (cancelRequest[requestName] = c);
      }),
    };
    if (method !== "GET") {
      options.data = body;
    }
    const response = await axiosInst(options);
    return response.data;
  } catch (error) {
    if (!error) {
      error = "Network / Server Error";
    }
    return error && error.response;
  }
}

export const GET = (
  url,
  module,
  body = {},
  headers = {},
  responseType,
  requestName = null,
  cancelPrevRequests
) =>
  callApi(
    url,
    module,
    "GET",
    headers,
    body,
    responseType,
    requestName,
    cancelPrevRequests
  );
export const POST = (url, module, body, headers = {}) =>
  callApi(url, module, "POST", headers, body);
export const PUT = (url, module, body, headers = {}) =>
  callApi(url, module, "PUT", headers, body);
export const PATCH = (url, module, body, headers = {}) =>
  callApi(url, module, "PATCH", headers, body);
export const DELETE = (url, module, body, headers = {}) =>
  callApi(url, module, "DELETE", headers, body);
