import { FETCH_ITEMS_REQUEST, FETCH_ITEMS_SUCCESS } from "./ActionType";

export const fetchItemsLandingPage = () => {
  return {
    type: FETCH_ITEMS_REQUEST,
  };
};

export const fetchMenuSuccess = (items) => {
  return {
    type: FETCH_ITEMS_SUCCESS,
    data: items,
  };
};


