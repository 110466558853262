/// API urls

export const commonApiUrl = "https://common.api.dev.surepoint.cloud";

export const conflictManagementApiUrl = "https://cm.api.dev.surepoint.cloud";

export const clientMatterInquiryApiUrl = "https://cmi.api.dev.surepoint.cloud";

export const attorneyFlowApiUrl = "https://tm.api.dev.surepoint.cloud";

export const accessUrl = "https://ce.api.dev.surepoint.cloud";

export const helpPageUrl = "https://help.dev.surepoint.cloud/docs/Default.htm#cshid=";

export const domainUrl = ".surepoint.cloud";

export const env_name = "dev";