import React from 'react'

const ErrorPage = () => {
    return (
        <div style={{ width: '100%', height: '100%', minHeight: '300px', padding: "10px 10px 0 20px", display: "flex", justifyContent: 'center', alignItems: 'center' } }>
            <span>You are not authorized to access this page.</span>
        </div>
    );
};

export default ErrorPage;

