//Tooltips keys
export const TOOLTIPS_KEYS = {
    TOOLTIP_ACCOUNT_INFO: "ACCOUNT_INFO",
    TOOLTIP_ACCOUNT_MENU: "ACCOUNT_MENU",
    TOOLTIP_HELP: "HELP",
    TOOLTIP_SEARCH: "SEARCH",
    TOOLTIP_SHOW_ACTIVE_TIMER_ONLY: "SHOW_ACTIVE_TIMER_ONLY",
    TOOLTIP_SHOW_ALL_TIMERS: "SHOW_ALL_TIMERS",
    TOOLTIP_SWITCH_SORT_ORDER: "SWITCH_SORT_ORDER",
    TOOLTIP_START: "START",
    TOOLTIP_PAUSE: "PAUSE",
    TOOLTIP_RESUME: "RESUME",
    TOOLTIP_STOP: "STOP",
    TOOLTIP_CLEARTIME: "CLEAR_TIME",
    TOOLTIP_SELECT_DATE: "SELECT_DATE",
    TOOLTIP_CLEAR: "CLEAR",
    TOOLTIP_SWITCH_DURATION_FORMAT: "SWITCH_DURATION_FORMAT",
    TOOLTIP_DICTATE: "DICTATE",
    TOOLTIP_CLOSE: "CLOSE",
    TOOLTIP_COPY: "COPY",
    TOOLTIP_EDIT: "EDIT",
    TOOLTIP_DELETE: "DELETE",
    TOOLTIP_SHOW_ACTIVE_ROLES_ONLY: "SHOW_ACTIVE_ROLES_ONLY",
    TOOLTIP_SHOW_ALL_ROLES: "SHOW_ALL_ROLES",
    TOOLTIP_SHOW_FIRM_AUTOTEXT_ONLY: "SHOW_FIRM_AUTOTEXT_ONLY",
    TOOLTIP_SHOW_PERSONAL_AUTOTEXT_ONLY: "SHOW_PERSONAL_AUTOTEXT_ONLY",
    TOOLTIP_SET_AS_TIMEKEEPER: "SET_AS_TIMEKEEPER",
    TOOLTIP_REMOVE_AS_TIMEKEEPER: "REMOVE_AS_TIMEKEEPER",
    TOOLTIP_SAVE: "SAVE",
    TOOLTIP_CANCEL: "CANCEL",
    TOOLTIP_SHOW_PASSWORD: "SHOW_PASSWORD",
    TOOLTIP_HIDE_PASSWORD: "HIDE_PASSWORD"
}

//values
export const TOOLTIPS_VALUES = {
    ACCOUNT_INFO: "Account Info",
    HELP: "Help",
    SEARCH: "Search",
    SHOW_ACTIVE_TIMER_ONLY: "Show active timer only",
    SHOW_ALL_TIMERS: "Show all timers",
    SWITCH_SORT_ORDER: "Switch sort order",
    START: "Start",
    PAUSE: "Pause",
    RESUME: "Resume",
    STOP: "Stop",
    CLEAR_TIME: "Clear time",
    SELECT_DATE: "Select date",
    CLEAR: "Clear",
    SWITCH_DURATION_FORMAT: "Switch duration format",
    DICTATE: "Dictate",
    CLOSE: "Close",
    SAVE: "save",
    CANCEL: "Cancel",
    SHOW_PASSWORD: "Show password",
    HIDE_PASSWORD: "Hide password",
    RESEND_CODE: "Resend Code",
    COPY: "Copy",
    EDIT: "Edit",
    DELETE: "Delete",
    SHOW_ACTIVE_ROLES_ONLY: "Show active roles only",
    SHOW_ALL_ROLES: "Show all roles",
    SHOW_FIRM_AUTOTEXT_ONLY: "Show firm autotext only",
    SHOW_PERSONAL_AUTOTEXT_ONLY: "Show personal autotext only",
    SET_AS_TIMEKEEPER: "Set as timekeeper",
    REMOVE_AS_TIMEKEEPER: "Remove as timekeeper"
}

export const HELP_LINKS = {
    ADD_TIME: "AddTime",
    AUTOTEXT: "Autotext",
    DELEGATES: "Delegates",
    DRAFT_TIME_ENTRY: "DraftTimeEntry",
    LANDING_PAGE: "Home",
    MANAGE_TIME: "ManageTime",
    MY_PROFILE: "MyProfile",
    PREFERENCE_PRACTICE: "PreferencesPractice",
    ROLES_ACCESS: "RoleAccess",
    ROLES: "Roles",
    USER_ACCESS: "UserAccess",
    USERS: "Users",
    TIME_ENTRY: "TimeEntry"
}
