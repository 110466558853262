class ActiveTimerAPIPostInput {
  clientId = "";
  subClientId = "";
  matterId = "";
  attorneyId = "";
  loggedInUserId = "";
  timerStatus = "";
  timerId = null;
}
export const gStore = {
  loggedInUserId: "afdemo1",
  delegateId: "",
  timeDuration: "00:00:00",
  activeTimerId: "",
  activeTimerAPIPostInput: new ActiveTimerAPIPostInput(),
};

export const activeTimerAPIPostInput = new ActiveTimerAPIPostInput();

export default gStore;

export const getDeleteId = () => {
  return gStore.delegateId;
};

export const updateTimerAPIinput = (
  clientId,
  subClientId,
  matterId,
  timerStatus,
  timerId
) => {
  gStore.activeTimerAPIPostInput = {
    clientId,
    subClientId,
    matterId,
    attorneyId: gStore.delegateId,
    loggedInUserId: gStore.loggedInUserId,
    timerStatus,
    timerId,
  };
};

let clearIntervalTimer = null;

export let setClearIntervalTimer = (token) => {
  console.log("new token", token);
  clearIntervalTimer && window.clearInterval(clearIntervalTimer);
  token && (clearIntervalTimer = token);
};
