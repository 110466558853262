import commonUtils from "../../utils/commonUtils";
import { DELETE, GET, PATCH, POST, PUT } from "./axiosclient";

export const conflictSearchApi = {
  getSearchTypesLOV: () => {
    return GET("core/party/api/v1/searchtypes", "CM", {});
  },
  getPartyCategoriesLOV: () => {
    return GET("core/party/api/v1/category", "CM", {});
  },
  getDraftClientsLOV: (searchText, page) => {
    const baseURL = `client/api/v1/clients?status=draft&page=${page}`;
    let url = searchText !== "" ? `${baseURL}&search=${searchText}` : baseURL;
    return GET(url, "COMMON", {}, {}, "", "clients", true);
  },
  getExistingClientsLOV: (
    searchText,
    pageOffset,
    user_id,
    delegate_user_id
  ) => {
    const baseURL = `client/api/v1/clients?status=active&delegateUserId=${delegate_user_id !== user_id ? delegate_user_id : user_id
      }&pageOffset=${pageOffset}&search=${searchText}`;
    //canclePrevRequests = 'true'
    return GET(baseURL, "COMMON", {}, {}, "", "clients", true);
  },
  getSubClients: (
    clientId,
    searchText,
    pageOffset,
    user_id,
    delegate_user_id
  ) => {
    const delegateId =
      delegate_user_id !== user_id ? delegate_user_id : user_id;
    const baseURL = `client/api/v1/clients/${clientId}/subclients?status=active&delegateUserId=${delegateId}&pageOffset=${pageOffset}&search=${searchText}`;
    return GET(baseURL, "COMMON", {});
  },
  getDraftMattersLOV: (clientId, subClientId, searchText, page) => {
    const baseURL = `matter/api/v1/matters?status=draft&clientId=${clientId}&page=${page}&search=${searchText}`;
    return GET(baseURL, "COMMON", {});
  },
  getExistingMattersLOV: (
    clientId,
    subClientId,
    searchText,
    pageOffset,
    user_id,
    delegate_user_id
  ) => {
    const delegateId =
      delegate_user_id !== user_id ? delegate_user_id : user_id;
    const baseURL = `matter/api/v1/matters?status=active&delegateUserId=${delegateId}&subClientId=${subClientId}&pageOffset=${pageOffset}&search=${searchText}`;
    return GET(baseURL, "COMMON", {});
  },
  getRegisteredUsers: (searchText, page = 1) => {
    if (searchText !== "") {
      const request_body = {
        name: searchText,
        preferredname: "",
        username: "",
        email: "",
        mobileno: "",
      };
      return POST(`users/api/v1/search?pageNo=${page}`, "AM", request_body);
    } else {
      return GET(`users/api/v1/users?pageNo=${page}`, "AM", {});
    }
  },
  getDeduplicationLOV: (client_id, party_category, searchText, page) => {
    return GET(
      `core/party/api/v1/parties/${party_category}?client_id=${client_id}&page=${page}&search=${searchText}`
    );
  },
  getPartyRelationships: () => {
    return GET("core/party/api/v1/relations", "CM", {});
  },
  getPartyStances: () => {
    return GET("core/party/api/v1/stance", "CM", {});
  },
  getPartyTypes: (areaoflaw_id) => {
    return GET(
      "partytype/api/v1/partytypes?areaoflaw_id=" + areaoflaw_id,
      "CM",
      {}
    );
  },
  addParty: (request_body, user_id, delegate_user_id) => {
    return POST(
      `formalsearch/api/v1/party?${commonUtils.appendDelegateUser(
        user_id,
        delegate_user_id
      )}`,
      "CM",
      request_body
    );
  },
  getSearchParties: (pageNumber, search_criteria_id, queryString = "") => {
    return GET(
      `formalsearch/api/v1/searchcriteria/${search_criteria_id}/parties?page=${pageNumber}${queryString}`,
      "CM",
      {}
    );
  },
  buildSearch: (request_body, user_id, delegate_user_id) => {
    const url = `formalsearch/api/v1/buildsearch?user_id=${user_id}&${commonUtils.appendDelegateUser(
      user_id,
      delegate_user_id
    )}`;
    return POST(url, "CM", request_body);
  },
  saveEditedSearchParty: (
    party_id,
    request_body,
    user_id,
    delegate_user_id
  ) => {
    return PATCH(
      `formalsearch/api/v1/party/${party_id}?${commonUtils.appendDelegateUser(
        user_id,
        delegate_user_id
      )}`,
      "CM",
      request_body
    );
  },
  startConflictSearch: (criteria_id, request_body) => {
    return POST(
      `formalsearch/api/v1/searchcriteria/${criteria_id}/searchstart`,
      "CM",
      request_body
    );
  },
  getInformalSearchNamesList: (
    search_criteria_id,
    area_of_law_id,
    delegate_user_id
  ) => {
    return GET(
      `formalsearch/api/v1/searchcriteria/${search_criteria_id}/parties/keywords?areaoflaw_id=${area_of_law_id}&delegateUserId=${delegate_user_id}`,
      "CM",
      {}
    );
  },
  setInformalSearchKeyword: (keywordId, request_body, delegate_user_id) => {
    return POST(
      `formalsearch/api/v1/party?informalKeywordId=${keywordId}&delegateUserId=${delegate_user_id}`,
      "CM",
      request_body
    );
  },

  //Formal Search - All search
  getAllSearchData: (
    user_id,
    delegate_user_id,
    pageOffset,
    queryString = ""
  ) => {
    const baseURL = `formalsearch/api/v1/searchcriteria?pageOffset=${pageOffset}&user_id=${user_id}${queryString}`;
    let url =
      delegate_user_id !== user_id
        ? `${baseURL}&delegate_user_id=${delegate_user_id}`
        : baseURL;
    return GET(url, "CM", {});
  },
  rerunSearches: (criteria_id, request_body, user_id, delegate_user_id) => {
    return POST(
      `formalsearch/api/v1/searchcriteria/${criteria_id}/searchrerun?${commonUtils.appendDelegateUser(
        user_id,
        delegate_user_id
      )}`,
      "CM",
      request_body
    );
  },
  changeStatusofOpenSearches: (id, request_body, user_id, delegate_user_id) => {
    return PUT(
      `formalsearch/api/v1/searchcriteria/${id}/status?${commonUtils.appendDelegateUser(
        user_id,
        delegate_user_id
      )}`,
      "CM",
      request_body
    );
  },
  getStatusOptionsofOpenSearches: () => {
    return GET("formalsearch/api/v1/searchcriteria/statustypes", "CM", {});
  },
  exportResults: (user_id, delegate_user_id, request_body) => {
    const baseURL = `report/api/v1/download?user_id=${user_id}`;
    let url =
      delegate_user_id !== user_id
        ? `${baseURL}&delegate_user_id=${delegate_user_id}`
        : baseURL;
    return POST(url, "CM", request_body);
  },

  // Parties and Records Dynamic Variables API's
  getDynamicVariables: () => {
    return GET("dynamicvariable/api/v1/dynamicvariables", "CM", {});
  },

  //Formal Search - View Search Results
  getAllSearchResultsCount: (id, queryString = "") => {
    const baseURL = `formalsearch/api/v1/searchcriteria/${id}/searchresults/count`;
    const url = queryString
      ? `${baseURL}?${queryString.substring(1, queryString.length)}`
      : baseURL;
    return GET(url, "CM", {});
  },

  getAdvanceSearchResultsCount: (id, queryString = "", request_body) => {
    const baseURL = `formalsearch/api/v1/searchcriteria/${id}/searchresults/filters/count`;
    const url = queryString
      ? `${baseURL}?${queryString.substring(1, queryString.length)}`
      : baseURL;
    return POST(url, "CM", request_body);
  },

  getSearchResultUserPreference: (id, user_id, sreenName) => {
    const baseURL = `formalsearch/api/v1/searchcriteria/${id}/searchresults/userpreference?user_id=${user_id}&screen_name=${sreenName}`;
    return GET(baseURL, "CM", {});
  },
  updateSearchResultUserPreference: (id, request_body) => {
    const baseURL = `formalsearch/api/v1/searchcriteria/${id}/searchresults/userpreference`;
    return POST(baseURL, "CM", request_body);
  },
  getAllSearchResultsData: (
    id,
    pageNumber,
    queryString = "",
    request_body,
    user_id,
    delegate_user_id
  ) => {
    if (Object.keys(request_body).length === 0) {
      const baseURL = `formalsearch/api/v1/searchcriteria/${id}/searchresults?page=${pageNumber}&user_id=${user_id}${queryString}`;
      let url =
        delegate_user_id !== user_id
          ? `${baseURL}&delegate_user_id=${delegate_user_id}`
          : baseURL;
      return GET(url, "CM");
    } else {
      const baseURL = `formalsearch/api/v1/searchcriteria/${id}/searchresults/filters?page=${pageNumber}&user_id=${user_id}${queryString}`;
      let url =
        delegate_user_id !== user_id
          ? `${baseURL}&delegate_user_id=${delegate_user_id}`
          : baseURL;
      return POST(url, "CM", request_body);
    }
  },
  getAllActionsData: (searchCriteriaId, resultsId) => {
    return GET(
      `formalsearch/api/v1/searchcriteria/${searchCriteriaId}/searchresults/${resultsId}/actions`,
      "CM",
      {}
    );
  },
  getAllActionWaiversData: (searchCriteriaId, resultsId) => {
    return GET(
      `formalsearch/api/v1/searchcriteria/${searchCriteriaId}/searchresults/${resultsId}/waiverhistory`,
      "CM",
      {}
    );
  },

  getAdvancedSearchFiltersData: (searchCriteriaId, user_id) => {
    return GET(
      `formalsearch/api/v1/searchcriteria/${searchCriteriaId}/searchresults/filters?user_id=${user_id}`
    );
  },

  getSubClientsByClientId: (user_id, searchCriteriaId, request_body = []) => {
    return POST(
      `formalsearch/api/v1/searchcriteria/${searchCriteriaId}/searchresults/filters/sub?page=1&user_id=${user_id}`,
      "CM",
      request_body
    );
  },

  getMatterByClientId: (user_id, searchCriteriaId, request_body = []) => {
    return POST(
      `formalsearch/api/v1/searchcriteria/${searchCriteriaId}/searchresults/filters/matter?page=1&user_id=${user_id}`,
      "CM",
      request_body
    );
  },

  saveNewAction: (
    request_body,
    searchCriteriaId,
    resultsId,
    user_id,
    delegate_user_id
  ) => {
    return POST(
      `formalsearch/api/v1/searchcriteria/${searchCriteriaId}/searchresults/${resultsId}/actions?user_id=${user_id}&${commonUtils.appendDelegateUser(
        user_id,
        delegate_user_id
      )}`,
      "CM",
      request_body
    );
  },
  saveNewBulkActions: (
    request_body,
    searchCriteriaId,
    user_id,
    delegate_user_id
  ) => {
    return POST(
      `formalsearch/api/v1/searchcriteria/${searchCriteriaId}/searchresults/actions?user_id=${user_id}&${commonUtils.appendDelegateUser(
        user_id,
        delegate_user_id
      )}`,
      "CM",
      request_body
    );
  },
  getActionDetailsForSelectedAction: (
    searchCriteriaId,
    resultsId,
    actionId
  ) => {
    return GET(
      `formalsearch/api/v1/searchcriteria/${searchCriteriaId}/searchresults/${resultsId}/actions?action_id=${actionId}`,
      "CM",
      {}
    );
  },
  getActionOptions: () => {
    return GET(`action/api/v1/actions?status=active`, "CM", {});
  },
  updateActionDetails: (
    searchCriteriaId,
    resultsId,
    actionId,
    request_body,
    user_id,
    delegate_user_id
  ) => {
    return PATCH(
      `formalsearch/api/v1/searchcriteria/${searchCriteriaId}/searchresults/${resultsId}/actions/${actionId}?${commonUtils.appendDelegateUser(
        user_id,
        delegate_user_id
      )}`,
      "CM",
      request_body
    );
  },
  deleteActionAttachments: (
    searchCriteriaId,
    resultsId,
    actionId,
    request_body
  ) => {
    return DELETE(
      `formalsearch/api/v1/searchcriteria/${searchCriteriaId}/searchresults/${resultsId}/actions/${actionId}`,
      "CM",
      request_body
    );
  },
  getLatestActionDetailsForSelectedResult: (searchCriteriaId, resultsId) => {
    return GET(
      `formalsearch/api/v1/searchcriteria/${searchCriteriaId}/searchresults/${resultsId}/actions?recent=true`,
      "CM",
      {}
    );
  },

  //Attachment New Appraoch
  addAttachmentToS3: (request_body) => {
    return POST(`document/api/v1/documents`, "COMMON", request_body);
  },
  getExpandedResultsData: (searchCriteriaId, resultsId) => {
    return GET(
      `formalsearch/api/v1/searchcriteria/${searchCriteriaId}/searchresults/${resultsId}/highlights`,
      "CM",
      {}
    );
  },

  //Waivers API Calls

  getAllWaiversData: (
    user_id,
    delegate_user_id,
    pageOffset,
    queryString = ""
  ) => {
    const baseURL = `waiver/api/v1/waivers?pageOffset=${pageOffset}&user_id=${user_id}${queryString}`;
    let url =
      delegate_user_id !== user_id
        ? `${baseURL}&delegate_user_id=${delegate_user_id}`
        : baseURL;
    return GET(url, "CM", {});
  },
  getViewDetailsForSpecificId: (id) => {
    return GET(`waiver/api/v1/waivers/${id}`, "CM", {});
  },
  saveNewWaiver: (user_id, delegate_user_id, request_body) => {
    return POST(
      `waiver/api/v1/waivers?delegateUserId=${delegate_user_id}&user_id=${user_id}`,
      "CM",
      request_body
    );
  },
  editWaiverViewDetails: (
    waiver_id,
    user_id,
    delegate_user_id,
    request_body
  ) => {
    return PATCH(
      `waiver/api/v1/waivers/${waiver_id}?delegateUserId=${delegate_user_id}&user_id=${user_id}`,
      "CM",
      request_body
    );
  },
  deleteWaiverAttachments: (waiverId, request_body) => {
    return DELETE(`waiver/api/v1/waivers/${waiverId}`, "CM", request_body);
  },
  fetchApplyRemoveWaiversRecordsData: (
    id,
    user_id,
    delegate_user_id,
    pageOffset
  ) => {
    const baseURL = `waiver/api/v1/waivers/${id}/apply?user_id=${user_id}&delegateUserId=${delegate_user_id}&pageOffset=${pageOffset}`;
    return GET(baseURL, "CM", {});
  },
  saveNewApplyRemoveWaiver: (
    waiver_id,
    user_id,
    delegate_user_id,
    request_body
  ) => {
    return POST(
      `waiver/api/v1/waivers/${waiver_id}/apply?delegateUserId=${delegate_user_id}&user_id=${user_id}`,
      "CM",
      request_body
    );
  },
  setWaiverStatus: (waiver_id, user_id, delegate_user_id, request_body) => {
    return PATCH(
      `waiver/api/v1/waivers/${waiver_id}/apply?delegateUserId=${delegate_user_id}&user_id=${user_id}`,
      "CM",
      request_body
    );
  },

  //Formal Search - All Client and Matter details

  getAllExistingClientsLOV: (
    searchText,
    pageOffset,
    user_id,
    delegate_user_id
  ) => {
    const delegateId =
      delegate_user_id !== user_id ? delegate_user_id : user_id;
    const baseURL = `client/api/v1/clients?status=all&delegateUserId=${delegateId}&pageOffset=${pageOffset}&search=${searchText}`;
    return GET(baseURL, "COMMON", {}, {}, "", "clients", true);
  },

  getAllExistingSubClientsForExistingClient: (
    clientId,
    searchText,
    pageOffset,
    user_id,
    delegate_user_id
  ) => {
    const delegateId =
      delegate_user_id !== user_id ? delegate_user_id : user_id;
    const baseURL = `client/api/v1/clients/${clientId}/subclients?status=all&delegateUserId=${delegateId}&pageOffset=${pageOffset}&search=${searchText}`;
    return GET(baseURL, "COMMON", {});
  },

  getAllExistingMattersForExistingClient: (
    clientId,
    subClientId,
    searchText,
    pageOffset,
    user_id,
    delegate_user_id
  ) => {
    const delegateId =
      delegate_user_id !== user_id ? delegate_user_id : user_id;
    const baseURL = `matter/api/v1/matters?status=all&delegateUserId=${delegateId}&subClientId=${subClientId}&pageOffset=${pageOffset}&search=${searchText}`;
    return GET(baseURL, "COMMON", {});
  },

  getAllExistingPartyForExistingClient: (
    clientId,
    matterId,
    searchText,
    page
  ) => {
    const baseURL = `core/party/api/v1/parties?client_id=${clientId}${matterId != "" ? "&matter_id=" + matterId : ""
      }&page=${page}`;
    const url = searchText !== "" ? `${baseURL}&search=${searchText}` : baseURL;
    return GET(url, "CM", {});
  },

  // Party & Relationship Databases
  getAllPartySources: () => {
    return GET(`searchsetting/api/v1/partysettings?type=PARTY`, "CM", {});
  },

  savePartySources: (request_body) => {
    return POST(
      "searchsetting/api/v1/partysettings?type=PARTY",
      "CM",
      request_body
    );
  },

  getIPMSSources: () => {
    return GET(`searchsetting/api/v1/partysettings?type=IPMS`, "CM", {});
  },

  saveIPMSSources: (request_body) => {
    return POST(
      "searchsetting/api/v1/partysettings?type=IPMS",
      "CM",
      request_body
    );
  },

  getPartyStanceData: (headers) => {
    return GET(`searchsetting/api/v1/partystancesettings`, "CM", {}, headers);
  },

  savePartyDataPartyType: (request_body) => {
    return PUT(
      `searchsetting/api/v1/partystancesettings/partytype`,
      "CM",
      request_body
    );
  },

  savePartyDataStance: (request_body) => {
    return PUT(
      `searchsetting/api/v1/partystancesettings/stancetype`,
      "CM",
      request_body
    );
  },

  getDataSyncJobRunningStatus: (type) => {
    return GET(
      `searchsetting/api/v1/entityjobstatus?entityType=${type}`,
      "CM",
      {}
    );
  },

  // Informal Search API's
  getSavedSearches: (pageNumber, delegate_user_id) => {
    return GET(
      `informalsearch/api/v1/searchcriteria?page=${pageNumber}&delegateUserId=${delegate_user_id}`,
      "CM",
      {}
    );
  },

  deleteSavedSearch: (criteria_id) => {
    return DELETE(
      `informalsearch/api/v1/searchcriteria/${criteria_id}`,
      "CM",
      {}
    );
  },

  getSearchCriteriaByCriteriaId: (criteria_id) => {
    return GET(`informalsearch/api/v1/searchcriteria/${criteria_id}`, "CM", {});
  },

  getSearchTermsByCriteriaId: (criteria_id) => {
    return GET(
      `informalsearch/api/v1/searchcriteria/${criteria_id}/searchterms`,
      "CM",
      {}
    );
  },

  getInformalSearchResults: (request_body, delegate_user_id) => {
    return POST(
      `informalsearch/api/v1/searchcriteria/search?delegateUserId=${delegate_user_id}`,
      "CM",
      request_body
    );
  },
  filterInformalSearchResultsEW: (request_body) => {
    return POST(
      `informalsearch/api/v1/searchcriteria/ewallfilter`,
      "CM",
      request_body
    );
  },
  checkInformalSearchName: (search_name, delegate_user_id) => {
    return GET(
      `informalsearch/api/v1/searchcriteria?name=${encodeURIComponent(
        search_name
      )}&delegateUserId=${delegate_user_id}`,
      "CM",
      {}
    );
  },

  getSearchResultsBySaveAndSearch: (
    request_body,
    overwriteFlag,
    delegate_user_id
  ) => {
    return POST(
      `informalsearch/api/v1/searchcriteria?overwrite=${overwriteFlag}&delegateUserId=${delegate_user_id}`,
      "CM",
      request_body
    );
  },

  getSearchResultsByRerun: (request_body, criteria_id, delegate_user_id) => {
    return POST(
      `informalsearch/api/v1/searchcriteria/${criteria_id}/searchrerun?delegateUserId=${delegate_user_id}`,
      "CM",
      request_body
    );
  },

  exportPDFResults: (user_id, delegate_user_id, request_body) => {
    const baseURL = `informalsearch/api/v1/searchcriteria/report?user_id=${user_id}`;
    let url =
      delegate_user_id !== user_id
        ? `${baseURL}&delegate_user_id=${delegate_user_id}`
        : baseURL;
    return POST(url, "CM", request_body);
  },

  getInformalConflictNotice: () => {
    return GET(`informalsearch/api/v1/searchcriteria/lmsconfig`, "CM", {});
  },

  //Common
  getDownloadURL: (key, folder) => {
    return GET(
      `document/api/v1/documents/?key=${key}&folder=${folder}&signedUrl=true`,
      "COMMON",
      {}
    );
  },

  downloadUploadPartiesTemplate: () => {
    return GET("formalsearch/api/v1/party/download", "CM", {});
  },

  uploadValidationApi: (delegate_user_id, uploadFilename, originalFileName) => {
    return POST(
      `formalsearch/api/v1/party/upload/validations?delegateUserId=${delegate_user_id}&fileName=${uploadFilename}&originalFileName=${originalFileName}`,
      "CM",
      {}
    );
  },

  uploadBulkPartiesFileUrl: (
    request_body,
    delegate_user_id,
    uploadFilename
  ) => {
    return POST(
      `formalsearch/api/v1/party/upload?delegateUserId=${delegate_user_id}&fileName=${uploadFilename}`,
      "CM",
      request_body
    );
  },

  getBalancesWidgetData: (balancesFields) => {
    const { client = "", subClient = "", matter = "", year, month } = balancesFields;
    return GET(
      `balance/api/v1/balances?clientId=${client.id}&subClientId=${subClient.id}&matterId=${matter.id}&year=${year}&month=${month}`,
      "CMI",
      {}
    );
  },
};
