import * as Types from "../../constants/ClientMatterInquiry/balances";
import { initialState } from "../initialstate";

export const ClientMatterInquiryBalancesReducer = (
    state = initialState.ClientMatterInquiryBalancesReducer,
    action
) => {
    switch (action.type) {
        case Types.GET_EXISTING_CLIENTS_SUCCEEDED:
            return {
                ...state,
                existingClients: action.data,
            };
        case Types.SET_CM_INQUIRY_LOADING:
            return {
                ...state,
                isLoading: action.isLoading,
            };
        case Types.SET_DISPLAY_WIDGET_SECTION:
            return {
                ...state,
                isWidgetsShow: action.isDisplay,
            };
        case Types.GET_EXISTING_MATTERS_SUCCEEDED:
            return {
                ...state,
                existingMatters: action.data,
            };
        case Types.GET_SUB_CLIENTS_SUCCEEDED:
            return {
                ...state,
                subClients: action.data,
            };
        case Types.SET_WIDGET_DATA_SUCCESS:
            return {
                ...state,
                widgetData: action.data,
            };
        case Types.UPDATE_EDITED_FIELD:
            return {
                ...state,
                balancesFields: {
                    ...state.balancesFields,
                    [action.fieldName]: action.updatedValue,
                },
            };
        default:
            return state;
    }
};
