import { AuthService } from "./authService";

export const getJWTToken = async () => {
  const { idToken, accessToken } = await AuthService.checkTokenExpiration();
  return new Promise(function (resolve) {
    // const token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : storage.getCookie("_AF_UserAuthTokenCookie");
    // const accesstoken = sessionStorage.getItem('accessToken') ? sessionStorage.getItem('accessToken') : storage.getCookie("_AF_UserAccessTokenCookie");
    if (idToken && accessToken) {
      resolve({
        token: idToken,
        accessToken: accessToken,
      });
    } else {
      resolve("");
    }
  });
};
