import { helpPageUrl } from "../../config";
import commonUtils from "../../utils/commonUtils";
import {
  SET_ERROR_MESSAGE,
  SET_SIGNING_OUT,
  RESET_SIGNING_OUT,
  REFRESH_STORE_ON_ERROR,
  SET_TAB_ITEMS_DATA,
  SET_UNSAVED_STATUS,
  SET_PERMISSIONS_DATA,
  FETCH_USER_PREFERANCE_DETAILS_SUCCEEDED,
  SET_PAGE_HELP_LINK,
  SET_ATTACHMENTS_LOADING,
  SET_USER_TIME_ZONE,
  SET_SUB_CLIENT_STATUS,
  SET_USER_DETAILS,
} from "../constants/appAction";
import { initialState } from "./initialstate";

const appReducer = (state = initialState.AppReducer, action) => {
  switch (action.type) {
    case SET_ERROR_MESSAGE:
      return {
        ...state,
        apiResponse: {
          ...action.data,
          message: commonUtils.getIntlMessage(action.data.message),
        },
        showMessage: true,
      };
    case SET_SIGNING_OUT:
      return {
        ...state,
        signingOut: true,
      };
    case RESET_SIGNING_OUT:
      return {
        ...state,
        signingOut: false,
      };
    case REFRESH_STORE_ON_ERROR:
      return {
        ...state,
        refresh: action.data,
      };
    case SET_TAB_ITEMS_DATA:
      return {
        ...state,
        breadcrumbData: action.bCrumbData,
        tabItems: action.tabItemsData,
      };
    case SET_UNSAVED_STATUS:
      return {
        ...state,
        unsavedChangesFlag: action.status,
      };
    case SET_PERMISSIONS_DATA:
      return {
        ...state,
        permissionsData: action.data,
      };
    case FETCH_USER_PREFERANCE_DETAILS_SUCCEEDED:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          expiryReminder: action.data.passwordResetNotification,
          expiryReminderMessage: action.data.passwordResetNotificationValue,
          preferenceSettings: action.data.userData.preference,
        },
      };
    case SET_PAGE_HELP_LINK:
      return {
        ...state,
        helpLink: `${helpPageUrl}${action.data}`,
      };
    case SET_ATTACHMENTS_LOADING:
      return {
        ...state,
        attachmentsLoading: action.isLoading,
      };
    case SET_USER_TIME_ZONE:
      return {
        ...state,
        userTimeZone: action.userTimeZone,
      };
    case SET_SUB_CLIENT_STATUS:
      return {
        ...state,
        subClientDisplayStatus: action.subClientDisplayStatus,
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        userName: action.userName,
      };
    default:
      return state;
  }
};
export default appReducer;
