import React from "react";
import { isEmpty, isEqual, xorWith, cloneDeep } from "lodash";
import moment from "moment-timezone";
import storage from "./storage";

const datesQueryParamMapping = {
  effective_start_date: "start_date",
  effective_end_date: "end_date",
};
const commonUtils = {
  setFocus: (elementTabIndex) => {
    var tabbables = document.querySelectorAll(".radioButtonInput"); //get all tabable elements
    for (var i = 0; i < tabbables.length; i++) {
      //loop through each element
      if (tabbables[i].tabIndex === Number(elementTabIndex) + 1) {
        //check the tabindex to see if it's the element we want
        tabbables[i].focus(); //if it's the one we want, focus it and exit the loop
        break;
      }
    }
  },
  getRandomNumber: () => {
    const crypto = window.crypto || window.msCrypto;
    var array = new Uint32Array(1);
    return crypto && crypto.getRandomValues(array)[0];
  },
  getPermissionsValueByCriteria: (data, id, fieldname, setting) => {
    return data
      ?.find((x) => x.id === id)
      ?.fields?.find((y) => y.id === fieldname)?.[setting];
  },
  getPermissionsValueByEnableField: (data, arr) => {
    if (!arr) return true;
    const [id, fieldname, setting] = arr;
    return data
      ?.find((x) => x.id === id)
      ?.fields?.find((y) => y.id === fieldname)[setting];
  },
  validateDelegationByFeature: (
    userId,
    delegateId,
    featureName,
    delegatesList
  ) => {
    if (delegateId && userId !== delegateId) {
      const featureItems = delegatesList?.filter(
        (d) => d.userDelegateId === delegateId && d.featureName === featureName
      );
      return featureItems?.length > 0 || false;
    }
    return true;
  },
  checkEmptyOrNullAndReturn: (value) => {
    if (value === "" || value === null || value === undefined) {
      return "";
    }
    return value;
  },
  changeDateFormat: (date, dateFormat = "MM/DD/YYYY") => {
    let dateInReqFormat = "";
    if (
      date !== "0000-00-00" &&
      date !== "" &&
      date !== 0 &&
      date !== null &&
      date !== undefined
    ) {
      dateInReqFormat = moment(date).format(dateFormat);
    }
    return dateInReqFormat;
  },
  checkISODateValid: (date) => {
    return moment(date, moment.ISO_8601).isValid();
  },
  changeUnixDateFormat: (date, dateFormat = "MM/DD/YYYY") => {
    let dateInReqFormat = "";
    if (
      date !== "0000-00-00" &&
      date !== "" &&
      date !== "0" &&
      date !== 0 &&
      date !== null &&
      date !== undefined
    ) {
      dateInReqFormat = moment
        .unix(date / 1000)
        .utc()
        .format(dateFormat);
    }
    return dateInReqFormat;
  },
  changeUtcDateFormat: (date, dateFormat = "MM/DD/YYYY") => {
    let dateInReqFormat = "";
    if (
      date !== "0000-00-00" &&
      date !== "" &&
      date !== "0" &&
      date !== 0 &&
      date !== null &&
      date !== undefined
    ) {
      dateInReqFormat = moment.utc(date).format(dateFormat);
    }
    return dateInReqFormat;
  },
  isRecordEditable: (timezone, effective_end_date) => {
    let systemDate = moment
      .tz(moment(), timezone)
      .format("MM/DD/YYYY HH:mm:ss");
    effective_end_date = moment(effective_end_date).format(
      "MM/DD/YYYY HH:mm:ss"
    );
    return moment(systemDate).isBefore(effective_end_date);
  },

  currentStartDate: (timezone) => {
    let startDate = moment.tz(moment(), timezone).format("MM/DD/YYYY");
    return startDate;
  },

  guid: () => {
    const cryptoUID = window.crypto || window.msCrypto;
    return cryptoUID && cryptoUID.randomUUID();
  },
  getIntlMessage: (key) => {
    const literals = storage?.getObject("literals");
    if (literals && key && literals[key]) {
      return literals[key];
    }
    return key || "";
  },
  getQueryString: (params) => {
    let queryString = "";
    Object.entries(params).forEach(([key, attr]) => {
      if (attr !== "") {
        //overidding the date format to 'YYYY-MM-DD' for date filter in the grid
        if (
          datesQueryParamMapping[key] ===
          datesQueryParamMapping.effective_start_date ||
          key ===
          (datesQueryParamMapping.effective_end_date ||
            datesQueryParamMapping.effective_start_date) ||
          datesQueryParamMapping[key] ===
          datesQueryParamMapping.effective_end_date
        ) {
          attr = commonUtils.changeDateFormat(attr, "YYYY-MM-DD");
        }
        //Encdoing the query params for unesacped characters.
        queryString = `${queryString}&${datesQueryParamMapping[key] || key
          }=${encodeURIComponent(attr)}`;
      }
    });
    return queryString;
  },
  filterDropdownSource: (data, searchText) => {
    if (searchText !== "") {
      return data.filter((p) => {
        return (
          (p.featureValue &&
            p.featureValue.toLowerCase().includes(searchText.toLowerCase())) ||
          (p.featureKey &&
            p.featureKey.toLowerCase().includes(searchText.toLowerCase())) ||
          (p.label && p.label.toLowerCase().includes(searchText.toLowerCase()))
        );
      });
    } else {
      return data;
    }
  },
  convertToDropdownFormat: (
    data,
    labelField,
    valueField,
    isMultiColumns = false,
    numberField
  ) => {
    if (isMultiColumns) {
      return data?.map((item) => {
        return {
          id: item[valueField],
          featureKey: String(item[numberField]),
          featureValue:
            item[labelField] && item[labelField] !== null
              ? item[labelField]
              : "",
          ...item,
        };
      });
    } else {
      return data?.map((item) => {
        return {
          value: item[valueField],
          label: item[labelField],
          ...item,
          default: true,
        };
      });
    }
  },
  addDays(date, days = 1) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  },
  convertToDropdownFormatOnlyNonblank: (
    data,
    labelField,
    valueField,
    isMultiColumns = false,
    numberField
  ) => {
    if (isMultiColumns) {
      return data
        ?.filter((x) => x[numberField])
        .map((item) => {
          return {
            id: item[valueField],
            featureKey: item[numberField],
            featureValue:
              item[labelField] && item[labelField] !== null
                ? item[labelField]
                : "",
            ...item,
          };
        });
    } else {
      return data?.map((item) => {
        return {
          value: item[valueField],
          label: item[labelField],
          default: true,
        };
      });
    }
  },

  dtoMapper: (key) => {
    switch (key) {
      case "effective_start_date":
        return "start_date";
      case "effective_end_date":
        return "end_date";
      default:
        return key;
    }
  },
  appendDelegateUser: (loggedInUserId, delegateUserId) => {
    return `delegateUserId=${delegateUserId === loggedInUserId ? "" : delegateUserId
      }`;
  },
  isArrayDeepComparisionEqual: (arr1, arr2) => {
    return isEmpty(xorWith(arr1, arr2, isEqual));
  },
  filterColumns: (data, showSubClient, propName) => {
    let finalColDef = cloneDeep(data);
    let filteredCols = finalColDef.filter((col) => {
      if (!showSubClient && col.data.propName === propName) {
        return false;
      } else {
        return true;
      }
    });
    return filteredCols;
  },
  getCurrentYear: () => {
    const currentDate = new Date();
    return {
      currentYear: currentDate.getFullYear(),
      currentMonth: currentDate.getMonth() + 1,
    }
  }
};

export const config = {
  EthicalWallFlag: "ethicalWallFlag", // If prop name change from BE, NO need to change overall app. We can update here to reflect everywhere.
  ExportResultsFormats: {
    EXCEL: {
      id: 1,
      contentType: "application/vnd.ms-excel",
      fileType: "excel",
    },
    CSV: { id: 2, contentType: "content-type:text/csv", fileType: "csv" },
    PDF: { id: 3, contentType: "application/pdf", fileType: "pdf" },
  },
  MaxUploadFileSize: 100000, //Kb (100MB),
  TIMEOUT_MINUTES_SHORT: 12, // In how many minutes timer will open if there is no activity - Shorter Period
  TIMEOUT_MINUTES_LONG: 705, // In how many minutes timer will open if there is no activity - Longer Period
  COUTDOWN_TIME_MINUTES_SHORT: 3, // Coutdown minutes in the timer - Shorter Period
  COUTDOWN_TIME_MINUTES_LONG: 15, // Coutdown minutes in the timer - Longer Period
  IDLE_SETTING_MINUTES_SHORT: 15, // It is dynamic will come from 'me' api
  IDLE_SETTING_MINUTES_LONG: 720, // It is dynamic will come from 'me' api
  LMS_SOURCE: "DB2",
  SurePoint: "SurePoint",
  DefaultAOL: {
    area_of_law: commonUtils.getIntlMessage("CM_LABEL_FIRM_LEVEL"),
    areaoflaw_id: 0,
  },
  DefaultALL: { label: commonUtils.getIntlMessage("CM_LABEL_ALL"), value: 0 },

  HelpLinks: {
    FORMAL_CONFLICT_SEARCH_ALL: "FormalConflictSearchAll",
    FORMAL_CONFLICT_SEARCH_NEW: "FormalConflictSearchNew",
    FORMAL_CONFLICT_SEARCH_PARTIES: "FormalConflictSearchParties",
    FORMAL_CONFLICT_SEARCH_RESULTS: "FormalConflictSearchResults",
    CONFLICT_STANCES: "ConflictStances",
    CONFLICT_PARTY_TYPES: "ConflictPartyTypes",
    CONFLICT_ASSOCIATIONS: "ConflictAssociations",
    CONFLICT_ACTIONS: "ConflictActions",
    CONFLICT_SEARCH_ENTITIES: "ConflictSearchEntities",
    CONFLICT_SYNONYMS: "ConflictSynonyms",
    CONFLICT_WORD_CHARACTER_EXCLUSIONS: "ConflictWordCharacterExclusions",
    CONFLICT_FORMAL_SEARCH_PARAMETERS: "ConflictFormalSearchParameters",
    CONFLICT_DATA_SYNC: "ConflictDataSync",
    CONFLICT_INFORMAL_SEARCH_CRITERIA: "InformalConflictSearchCriteria",
    CONFLICT_INFORMAL_SAVED_SEARCHES: "InformalConflictSearchSaved",
    CONFLICT_WAIVERS: "ConflictWaivers",
    CONFLICT_WAIVERS_ATTACHMENTS: "ConflictWaiverAttachments",
    CONFLICT_ACTIONS_ATTACHMENTS: "ConflictActionsAttachments",
    CONFLICT_DRAFT_TO_EXISTING: "ConflictDraftToExisting",
    CONFLICT_ACTIONS_ATTACHMENTS_BULK: "ConflictActionsAttachmentsBulk",
    INFORMAL_SEARCH_SEARCH_CRITERIA: "InformalConflictSearchCriteria",
    INFORMAL_SEARCH_SAVED_SEARCHES: "InformalConflictSearchSaved",
    FORMAL_CONFLICT_DATA_IMPORT_DATA: "DataImportData",
  },

  JOB_RUNNING_COMPLETED: "completed",
  PARTY: "party",
  IPMS: "ipms",
  ERROR: "error",
};

export const FeatureNames = {
  ClientMatterInquiry: {
    ModuleName: "MG-Practice-MD-CMInquiry",
    FeatureName: "MG-Practice-MD-CMI-Balances",
  },
};

export default commonUtils;