import React, { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import SessionOutCountdown from '../Common/Confirmation/SessionOutCountdown';

const InactivityDetection = ({ timeoutMinutes, logOutHandler, countDownTime, configuration}) => {
    const {
        TIMEOUT_MINUTES_SHORT,
        COUTDOWN_TIME_MINUTES_LONG,
        IDLE_SETTING_MINUTES_LONG,
        COUTDOWN_TIME_MINUTES_SHORT
    } = configuration;
    const [countdownRemainingTime, setCountdownRemainingTime] = useState(COUTDOWN_TIME_MINUTES_SHORT * 60 * 1000);
    const [isSessionExpired, setIsSessionExpired] = useState(false);

    useEffect(() => {
        reset();
        window.addEventListener('storage', onSessionExpiredHandler);
        return (() => {
            window.removeEventListener('storage', onSessionExpiredHandler);
        });
    }, []);

    const onSessionExpiredHandler = () => {
        if (localStorage.getItem("sessionExpired") === "true")
            setIsSessionExpired(true);
        else
            setIsSessionExpired(false);
    };

    useEffect(() => {
        setTabTitle()
    }, [isSessionExpired, localStorage.getItem("sessionExpired")]);

    /** set tab title when session expires */
    function setTabTitle() {
        if (isSessionExpired || localStorage.getItem("sessionExpired") === "true") {
            let prevTitle = document.title;
            if (document.title !== "Inactivity Timeout") {
                sessionStorage.setItem("prev_title", prevTitle);
            }
            document.title = "Inactivity Timeout";
        } else {
            document.title = sessionStorage.getItem("prev_title");
        }
    }

    const handleOnIdle = (event) => {
        setIsSessionExpired(true);
        localStorage.setItem("sessionExpired", true);
        pause();

        let idealDuraionSeconds = Math.floor((Date.now() - getLastActiveTime()) / 1000);
        let timeoutSeconds = (timeoutMinutes === TIMEOUT_MINUTES_SHORT ? COUTDOWN_TIME_MINUTES_LONG : IDLE_SETTING_MINUTES_LONG) * 60;
        let remainingTime = (timeoutSeconds * 1000 - idealDuraionSeconds * 1000);
        console.log("idealDuraionSeconds ", idealDuraionSeconds);
        console.log("timeoutSeconds, timeoutMinutes:", timeoutSeconds, timeoutMinutes);
        console.log("remainingTime, countDownTime, date:", remainingTime, countDownTime, new Date());
        localStorage.setItem("idleDuration_timeoutMinutes_countdownRemainingTime_time", idealDuraionSeconds + "," + timeoutMinutes + "," + (countDownTime < 0 ? 0 : countDownTime) + "," + new Date());
        setCountdownRemainingTime(remainingTime < 0 ? 0 : countDownTime);
    };

    const { getLastActiveTime, reset, pause } = useIdleTimer({
        timeout: 1000 * 60 * timeoutMinutes,
        onIdle: handleOnIdle,
        startOnMount: true,
        debounce: 500,
        crossTab: {
            emitOnAllTabs: true,
        },
    });

    const onStayHandler = () => {
        reset();
        localStorage.setItem("sessionExpired", false);
        setIsSessionExpired(false);
    }

    return (
        <>
            <SessionOutCountdown
                message={"You are about to be logged out due to inactivity"}
                sessionExpiredProp={isSessionExpired}
                popupVisibleHandler={onStayHandler}
                onLogout={logOutHandler}
                onStay={onStayHandler}
                countdownRemainingTime={countdownRemainingTime}
            />
        </>
    );
}

export default InactivityDetection;