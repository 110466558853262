// import { Fetch } from "./fetch";
import { DELETE, GET, POST } from "./axiosclient";

//export const requestInProgress = {};

export const globalApi = {
  matters: async (delegateUserId, page = 1, searchParams) => {
    if (searchParams) {
      searchParams.activeTimerFlag = searchParams.activeTimerFlag
        ? "On"
        : "Off";
    }
    return !searchParams
      ? GET(
          "/core/attorney/api/v1/clientmatters?delegateUserId=" +
            delegateUserId +
            "&page=" +
            page +
            "&loggedInUserId=1",
          "TM"
        )
      : POST(
          "/core/attorney/api/v1/clientmatters/search?page=" + page,
          "TM",
          searchParams
        );
  },
  menus: () => {
    return GET("/core/main/api/v2/menus", "TM");
  },
  delegates: async () => {
    // return Fetch("/delegates?userId=" + param);
    return GET("/core/main/api/v1/delegates", "TM");
  },
  languageMessages: async () => {
    return GET("/core/main/api/v1/literals", "TM");
  },
  updateTimerStatus: async (apiData) => {
    // console.log("network", apiData);
    return GET("/core/attorney/api/v1/clientmatters/timer", "TM", apiData);
  },

  preferences: async (apiData, method) => {
    if (method === "delete") {
      return DELETE("/core/main/api/v2/preferences", "TM", apiData);
    } else if (method === "post") {
      return POST("/core/main/api/v2/preferences", "TM", apiData);
    }
  },
};
