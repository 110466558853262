import { SET_AUTH_DETAILS, RESET_AUTH_DETAILS } from "../../actions/ActionType";
import { initialState } from "../initialstate";
import {
  GetSetDelegateData,
  GetSetPermissionsData,
  GetSetLiteralData,
  GetSetMeData,
  GetSetMenuData,
} from "@attorneyflow-mf/styleguide";

export const AuthReducer = (state = initialState.AuthReducer, action) => {
  switch (action.type) {
    case SET_AUTH_DETAILS:
      return {
        ...state,
        user: action && action.data && action.data.user,
        email: action && action.data && action.data.email,
        isAuth: true,
      };
    case RESET_AUTH_DETAILS:
      GetSetDelegateData(null, true);
      GetSetPermissionsData(null, true);
      GetSetLiteralData(null, true);
      GetSetMeData(null, true);
      GetSetMenuData(null, true);
      return {
        ...state,
        user: null,
        email: null,
        isAuth: false,
      };
    default:
      return state;
  }
};

export default AuthReducer;
