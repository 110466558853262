/* eslint-disable prettier/prettier */
// import {​​​​​ all }​​​​​ from 'redux-saga/effects';
import globalSaga from "./globalSaga";
import { all } from "redux-saga/effects";

import appSaga from "./appSaga";
import balancesSaga from "./ClientMatterInquiry/balancesSaga";

export default function* rootSaga() {
  yield all([
    globalSaga(),
    appSaga(),
    balancesSaga(),
  ]);
}
