import {
  SET_ERROR_MESSAGE,
  SET_SIGNING_OUT,
  RESET_SIGNING_OUT,
  REFRESH_STORE_ON_ERROR,
  GET_CM_LITERALS,
  SET_UNSAVED_STATUS,
  FETCH_USER_PREFERANCE_DETAILS,
  FETCH_USER_PREFERANCE_DETAILS_SUCCEEDED,
  DOWNLOAD_ATTACHMENT,
  SET_PAGE_HELP_LINK,
  SET_ATTACHMENTS_LOADING,
  SHOW_DELEGATE_FEATURE_HANDLER,
  SET_USER_TIME_ZONE,
  SET_SUB_CLIENT_STATUS,
  SET_USER_DETAILS,
} from "../constants/appAction";

export const setErrorMessage = (payload) => {
  return {
    type: SET_ERROR_MESSAGE,
    data: payload,
  };
};

export const setSigningOutStatus = (payload) => {
  return {
    type: SET_SIGNING_OUT,
  };
};

export const resetSigningOutStatus = (payload) => {
  return {
    type: RESET_SIGNING_OUT,
  };
};

export const refreshStoreOnError = (payload) => {
  return {
    type: REFRESH_STORE_ON_ERROR,
    data: payload,
  };
};

export const getCMLiterals = () => ({
  type: GET_CM_LITERALS,
});

export const setUnsavedStatus = (status) => ({
  type: SET_UNSAVED_STATUS,
  status,
});

export const fetchUserPreferenceDetails = () => ({
  type: FETCH_USER_PREFERANCE_DETAILS,
});

export const fetchUserPreferenceDetailsSucceeded = (data) => ({
  type: FETCH_USER_PREFERANCE_DETAILS_SUCCEEDED,
  data,
});

export const downloadAttachment = (id, _loadingAction, s3Folder) => ({
  type: DOWNLOAD_ATTACHMENT,
  id,
  _loadingAction,
  s3Folder,
});

export const setAttachmentsLoading = (isLoading) => ({
  type: SET_ATTACHMENTS_LOADING,
  isLoading,
});

export const setPageHelpLink = (data) => {
  return {
    type: SET_PAGE_HELP_LINK,
    data,
  };
};

export const showDelegateFeatureHandler = (key, value) => ({
  type: SHOW_DELEGATE_FEATURE_HANDLER,
  key,
  value,
});

export const setUserTimezone = (userTimeZone) => ({
  type: SET_USER_TIME_ZONE,
  userTimeZone,
});

export const setUserDetails = ({ firstName, lastName, userName }) => ({
  type: SET_USER_DETAILS,
  firstName,
  lastName,
  userName,
});

export const setSubClientStatus = (subClientDisplayStatus) => ({
  type: SET_SUB_CLIENT_STATUS,
  subClientDisplayStatus,
});