import Cookies from "universal-cookie";
import { domainUrl } from "../config";

const cookies = new Cookies();

const isJson = (item) => {
  item = typeof item !== "string" ? JSON.stringify(item) : item;
  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }
  if (typeof item === "object" && item !== null) {
    return true;
  }
  return false;
};

const storage = {
  html5StorageEnabled: window.localStorage && window.sessionStorage,
  // html5StorageEnabled: false,
  appStoragePrefix: "_",

  set: (name, value, isForSession) => {
    if (storage.html5StorageEnabled) {
      if (isForSession) {
        sessionStorage.setItem(storage.appStoragePrefix + name, value);
      } else {
        localStorage.setItem(storage.appStoragePrefix + name, value);
      }
    } else {
      if (isForSession) {
        cookies.set(storage.appStoragePrefix + name, value, { expires: 0 });
      } else cookies.set(storage.appStoragePrefix + name, value);
    }
  },
  setCookie: (name, value) => {
    cookies.set(storage.appStoragePrefix + name, value, {
      path: "/",
      domain: domainUrl,
    });
  },

  setObject: (name, value, isForSession) => {
    localStorage.setItem(
      storage.appStoragePrefix + name,
      JSON.stringify(value)
    );
  },

  // getter for the set key value pair
  get: (name) => {
    if (storage.html5StorageEnabled) {
      const sessionValue = sessionStorage.getItem(
        storage.appStoragePrefix + name
      );
      if (sessionValue != null) {
        return sessionValue;
      }
      return localStorage.getItem(storage.appStoragePrefix + name);
    } else {
      return cookies.get(storage.appStoragePrefix + name);
    }
  },

  getCookie: (name) => {
    return cookies.get(name);
  },

  //getter for a set object
  getObject: (name) => {
    const localValue = localStorage.getItem(storage.appStoragePrefix + name);
    if (localValue != null) {
      if (isJson(localValue)) return JSON.parse(localValue);
      else console.error(localValue + " is not a JSON object");
    }
  },
  //getter for a set object
  getObjectUtil: (name) => {
    if (storage.html5StorageEnabled) {
      const sessionValue = sessionStorage.getItem(
        storage.appStoragePrefix + name
      );
      if (sessionValue != null) {
        if (isJson(sessionValue)) return JSON.parse(sessionValue);
        else console.error(sessionValue + " is not a JSON object");
      }
      const localValue = localStorage.getItem(storage.appStoragePrefix + name);
      if (localValue != null) {
        if (isJson(localValue)) return JSON.parse(localValue);
        else console.error(localValue + " is not a JSON object");
      }
    } else {
      const cookieValue = cookies.get(storage.appStoragePrefix + name);
      if (cookieValue != null) {
        if (isJson(cookieValue)) return JSON.parse(cookieValue);
        else console.error(cookieValue + " is not a JSON object");
      }
    }
  },

  clearLocalStorge: () => {
    Object.keys(localStorage).forEach((key) => {
      if (key !== "devtools" && !key.includes("import-map-override"))
        localStorage.removeItem(key);
      //localStorage.removeItem('persist:root')
    });
  },
  setSessionStorageValue: (key, value) => {
    if (value && isJson(value)) {
      sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      sessionStorage.setItem(key, value);
    }
  },
  getSessionStorageValue: (key) => {
    const sessionValue = sessionStorage.getItem(key);
    if (!sessionValue) return sessionValue;
    if (isJson(sessionValue)) return JSON.parse(sessionValue);
    else return sessionValue;
  },
};
export default storage;
