import {GET,POST,PUT} from "./axiosclient"

const personasUrl = "/personas/api/v1/";
const usersUrl = "/users/api/v1/";
const listingUrl = "listings/api/v1/";
const authUrl = "/auth/api/v1/"

export const userApi = {
  //Access Page Single Users Card API
  userCard: (id) => {
    return GET(
      `${usersUrl}users/` + id,
      "AM",
    );
  },

  //Access Page Users List API
  users: async (searchParams, page = 1) => {
    return !searchParams
      ? GET(
        `${usersUrl}users?pageNo=` + page,
        "AM",
      )
      : POST(
          `${usersUrl}search?pageNo=` + page,
          "AM",
        searchParams,
      );
  },

  roles: (page = 1, searchParams) => {
    return !searchParams
      ? GET(
        `${personasUrl}personas?pageNo=` + page,
        "AM",
      )
      : POST(
          `${personasUrl}search?pageNo=` + page,
          "AM",
        searchParams,
      );
  },
  validRoles: (id) => {
    return GET(
      `${personasUrl}personas?userId=${id}&action=activeRoles`,
      "AM",
    )
  },
  allRoles: () => {
    return GET(
      `${personasUrl}personas`,
      "AM",
    )
  },

  roleCard: (id) => {
    return GET(
      `${personasUrl}personas/` + id,
      "AM",
    );
  },

  roleCardSort: (id, sort) => {
    return GET(
      `${personasUrl}personas/${id}?sort=${sort}`,
      "AM",
    );
    },
  
  getUserDetails: () => {
    return GET(
      `${authUrl}me/`,
      "AM",

    );
  },

  updatePassword: (data) => {
    return PUT(
        `${authUrl}me/`,
        "AM",
      data,
    );
  },

  getSpecificUser: (id) => {
    return GET(
      `${usersUrl}users/` + id,
      "AM",
    );
  },

  updateUser: (data) => {
    return PUT(
        `${usersUrl}users/`,
        "AM",
      data,

    );
  },

  getUsersForRole: (name, page) => {
    return GET(
      `${listingUrl}users?name=${name}`,
      "AM",
    );
  },

  getSuites: () => {
    return GET(
      `${listingUrl}suites`,
      "AM",
    );
  },

  getModules: (id) => {
    return GET(
      `${listingUrl}modules/${id}`,
      "AM",
    );
  },

  getFeatures: (id) => {
    return GET(
      `${listingUrl}menus/${id}`,
      "AM",
    );
  },

  addPersona: (data, type) => {
    return `${type}`(
        `${personasUrl}personas`,
        "AM",
      data,
    );
  },
  pool: async (apiData) => {
    //  console.log("apiData=========", apiData);
    return POST(
        "/login/api/v1/pool",
        "AM",
      apiData,
    );
    },
  
  logout: async (apiData) => {
    //  console.log("apiData=========", apiData);
    return POST(
        "/auth/api/v2/logout",
        "AM",
      apiData,
    );
  },
  getPermissions: async (sys_user_id) => {
    return GET(
      "/auth/api/v2/users/" +
      sys_user_id +
      "/permissions",
      "AM",
    );
    },
  
  adminResetPassword: async (id) => {
    return GET(
      `/users/api/v1/${id}/password/reset`,
      "AM",
    );
  },
};
