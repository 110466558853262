import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import "./ConfirmationModal.scss";
import commonUtils from "../../../utils/commonUtils";

function ConfirmationModal(props) {
  const {
    role,
    show,
    message,
    parentCallback,
    headerText = "Confirmation",
    noLabel = commonUtils.getIntlMessage("CANCEL"),
    yesLabel = commonUtils.getIntlMessage("OK"),
    imageSrc = "/images/icon_warning.svg",
    noIcon = "pi pi-times",
    yesIcon = "pi pi-check",
    hideNoIcon = false,
  } = props;

  const [displayConfirmation, setDisplayConfirmation] = useState(show);

  useEffect(() => {
    setDisplayConfirmation(show);
  }, [show]);

  const dialogFooter = (
    <div>
      {!hideNoIcon && (
        <Button
          label={noLabel}
          icon={noIcon}
          onClick={(e) => {
            setDisplayConfirmation(false);
            parentCallback({ callBackFrom: role, newVal: false });
          }}
          data-testid="button1"
          className="p-button-text no-button"
        />
      )}
      <Button
        label={yesLabel}
        icon={yesIcon}
        onClick={(e) => {
          parentCallback({ callBackFrom: role, newVal: true });
          setDisplayConfirmation(false);
        }}
        data-testid="button2"
        className="yes-button"
      />
    </div>
  );
  return (
    <div className="Confirmation-popup-cm">
      <Dialog
        closable={false}
        header={headerText}
        visible={displayConfirmation}
        modal
        footer={dialogFooter}
        onHide={() => setDisplayConfirmation(false)}
      >
        <div className="confirmation-content">
          <div>
            <img title="" alt="" className="af-warning" src={imageSrc} />
          </div>
          <div className="confirmation-msg">{message}</div>
        </div>
      </Dialog>
    </div>
  );
}

export default ConfirmationModal;
