import {
  FETCH_CLIENTITEMS_REQUEST,
  FETCH_CLIENTITEMS_SUCCESS,
  FETCH_CLIENTITEMS_FAILURE,
  FETCH_TIMEKEEPERLIST_REQUEST,
  FETCH_TIMEKEEPERLIST_SUCCESS,
  SET_SELECTED_TIMEKEEPER,
  SET_ROUNDING_METHOD,
  SET_FEATURE,
  SET_DURATION_TOGGLE,
  SET_DELEGATE_FEATURE
} from "./ActionType";

export const fetchItemsClientTimeManagementPageRequest = () => {
  return {
    type: FETCH_CLIENTITEMS_REQUEST,
  };
};

export const fetchItemsClientTimeManagementPageSuccess = (items) => {
  return {
    type: FETCH_CLIENTITEMS_SUCCESS,
    data: items,
  };
};

export const fetchItemsClientTimeManagementPageFailure = (error) => {
  return {
    type: FETCH_CLIENTITEMS_FAILURE,
    data: error,
  };
};

export const fetchTimeKeeperListRequest = () => {
  return {
    type: FETCH_TIMEKEEPERLIST_REQUEST,
  };
};

export const fetchTimeKeeperListSuccess = (timeKeeperList) => {
  return {
    type: FETCH_TIMEKEEPERLIST_SUCCESS,
    data: timeKeeperList,
  };
};

export const setSelectedTimeKeeperFeature = (featureName, featureId) => {
  return {
    type: SET_FEATURE,
    data: { FeatureName: featureName, FeatureId: featureId }
  };
};

export const fetchItemsFilteredClientTimeManagementPage = (data) => {
  return async (dispatch) => {
    dispatch(fetchItemsClientTimeManagementPageSuccess(data));
  };
};

export const setSelectedTimeKeeperStoreAction = (
  delegateId,
  dateFormat,
  attorneyCode,
  userDelegateRoundingMethod,
  timekeeper
) => {
  return {
    type: SET_SELECTED_TIMEKEEPER,
    data: {
      delegateId: delegateId,
      dateFormat: dateFormat,
      attorneyCode: attorneyCode,
      userDelegateRoundingMethod: userDelegateRoundingMethod,
      timekeeper: timekeeper
    },
  };
};

export const setRoundingMethod = (userDelegateRoundingMethod) => {
  return {
    type: SET_ROUNDING_METHOD,
    data: {
      userDelegateRoundingMethod: userDelegateRoundingMethod,
    },
  };
};

export const setDurationToggle = (toggle) => {
  return {
    type: SET_DURATION_TOGGLE,
    data: {
      loggedInUserToggle: toggle,
    }
  }
};

export const setDelegateFeature = (moduleName, featureName) => {
  return {
    type: SET_DELEGATE_FEATURE,
    moduleName,
    featureName
  };
};