import React, { Suspense } from "react";
import Spinner from "../components/Common/Spinner/Spinner";

const Balances = React.lazy(() =>
  import("../components/ClientMatterInquiry/Balances/Balances")
);
const Activities = React.lazy(() =>
  import("../components/ClientMatterInquiry/Activities/Activities")
);
const CRM = React.lazy(() =>
  import("../components/ClientMatterInquiry/CRM/CRM")
);
const Information = React.lazy(() =>
  import("../components/ClientMatterInquiry/Information/Information")
);

export const renderFunc = function (RouteComponent, customProps = {}) {
  return function (routeProps) {
    return (
      <Suspense fallback={<Spinner />}>
        <RouteComponent {...routeProps} {...customProps} />
      </Suspense>
    );
  };
};

const routes = (permissionsData) => {
  return [
    {
      id: "/default",
      path: "/ClientMatterInquiry",
      checkPath: "/ClientMatterInquiry",
      render: renderFunc(Balances)
    },
    {
      id: "/balances",
      path: "/ClientMatterInquiry/Balances",
      checkPath: "/ClientMatterInquiry",
      render: renderFunc(Balances)
    },
    {
      id: "/activities",
      path: "/ClientMatterInquiry/Activities",
      checkPath: "/ClientMatterInquiry",
      render: renderFunc(Activities)
    },
    {
      id: "/crm",
      path: "/ClientMatterInquiry/CRM",
      checkPath: "/ClientMatterInquiry",
      render: renderFunc(CRM)
    },
    {
      id: "/information",
      path: "/ClientMatterInquiry/Information",
      checkPath: "/ClientMatterInquiry",
      render: renderFunc(Information)
    },

  ];
};

export default routes;