import moment from "moment";
import commonUtils, { config } from "../../utils/commonUtils";

export const initialState = {
  LandingPageReducer: {
    loading: false,
    items: [],
    error: "",
  },

  AuthReducer: {
    user: null,
    email: null,
    isAuth: false,
  },

  TimeManagementReducer: {
    loading: false,
    loadingTimeKeeper: false,
    timeKeeperList: [],
    clientitems: [],
    error: "",
    errorTimeKeeper: "",
    clientFilteredData: [],
    userID: "",
    delegateId: "",
    dateFormat: null,
    attorneyCode: "",
    timeKeeperFeatureName: "",
    timeKeeperFeatureId: "",
    userDelegateRoundingMethod: "NORMAL",
    timekeeper: false,
    allRestrictedWords: {},
  },

  AppReducer: {
    apiResponse: {},
    loading: false,
    showMessage: false,
    signingOut: false,
    tabItems: [],
    breadcrumbData: [],
    unsavedChangesFlag: false,
    preferences: {
      userSessionTimeout: 15,
      expiryReminder: false,
      expiryReminderMessage: "",
      preferenceApiData: [],
      preferenceSettings: null,
    },
    permissionsData: [],
    helpLink: "",
    attachmentsLoading: false,
    userTimeZone: "",
    subClientDisplayStatus: true,
    firstName: "",
    lastName: "",
    userName: "",
  },

  ClientMatterInquiryBalancesReducer: {
    existingClients: [],
    subClients: [],
    existingMatters: [],
    balancesFields: {
      client: {},
      subClient: {},
      matter: {},
      year: commonUtils.getCurrentYear().currentYear,
      month: commonUtils.getCurrentYear().currentMonth,
    },
    isWidgetsShow: false,
    widgetData: {},
  },
};