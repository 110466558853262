import { FETCH_ITEMS_SUCCESS, FETCH_ITEMS_FAILURE } from "../../actions/ActionType";
import { initialState } from "../../reducers/initialstate";

export const LandingPageReducer = (
  state = initialState.LandingPageReducer,
  action
) => {
  switch (action.type) {
    case FETCH_ITEMS_SUCCESS:
      return {
        loading: false,
        items: action.data,
        error: "",
      };
    case FETCH_ITEMS_FAILURE:
      return {
        loading: false,
        items: [],
        error: action.data,
      };
    default:
      return state;
  }
};

export default LandingPageReducer;
