import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ErrorPage from '../components/Common/ErrorPage';
import routes from './routes';

const hasIntersection = (checkpath, path, data) => {
    let urlFound = false;
    if (checkpath === path) {
        return true;
    }
    data.moduleGroups?.forEach(modGroup => {
        if (!urlFound) {
            const urlExists = modGroup.modules.findIndex(x => x.url.trim().toLowerCase() === checkpath.trim().toLowerCase())
            if (urlExists !== -1 && !urlFound) {
                const deepurlExists = modGroup.modules[urlExists].menus.findIndex(x => x.url.trim().toLowerCase() === path.trim().toLowerCase())
                if (deepurlExists !== -1 && !urlFound) {
                    urlFound = true;
                }
            }
        }
    });
    return urlFound;
}

const ProtectedRoutes = ({ menuData, permissionData }) => <>
    <Switch>
        {
            routes(permissionData).map((prop, key) => hasIntersection(prop.checkPath, prop.path, menuData) ? <Route
                exact path={prop.path}
                render={prop.render}
                key={prop.path + key}
            /> : <Route
                exact path={prop.path}
                component={ErrorPage}
                key={prop.path + key} />)
        }
    </Switch>
</>;


export default ProtectedRoutes
