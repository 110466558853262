export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
export const SET_SIGNING_OUT = "SET_SIGNING_OUT";
export const RESET_SIGNING_OUT = "RESET_SIGNING_OUT";
export const REFRESH_STORE_ON_ERROR = "REFRESH_STORE_ON_ERROR";
export const GET_CM_LITERALS = "GET_CM_LITERALS";
export const SET_BREADCRUMB_DATA = "SET_BREADCRUMB_DATA";
export const SET_TAB_ITEMS_DATA = "SET_TAB_ITEMS_DATA";
export const SET_UNSAVED_STATUS = "SET_UNSAVED_STATUS";
export const SET_PERMISSIONS_DATA = "SET_PERMISSIONS_DATA";
export const FETCH_USER_PREFERANCE_DETAILS = "FETCH_USER_PREFERANCE_DETAILS";
export const FETCH_USER_PREFERANCE_DETAILS_SUCCEEDED = "FETCH_USER_PREFERANCE_DETAILS_SUCCEEDED";
export const DOWNLOAD_ATTACHMENT = "DOWNLOAD_ATTACHMENT";
export const SET_ATTACHMENTS_LOADING = "SET_ATTACHMENTS_LOADING";
export const SET_PAGE_HELP_LINK = "SET_PAGE_HELP_LINK";
export const SHOW_DELEGATE_FEATURE_HANDLER = "SHOW_DELEGATE_FEATURE_HANDLER";
export const SET_USER_TIME_ZONE = "SET_USER_TIME_ZONE";
export const SET_SUB_CLIENT_STATUS = "SET_SUB_CLIENT_STATUS";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
