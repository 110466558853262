import { combineReducers } from "redux";
import LandingPageReducer from "../reducers/LandingPageReducer/LandingPageReducer";
import AuthReducer from "../reducers/AuthReducer/AuthReducer";
import TimeManagementReducer from "../reducers/TimeManagementReducer/TimeManagement";
import { ClientMatterInquiryBalancesReducer } from "./ClientMatterInquiry/balancesReducer";
import appReducer from "./appReducer";


const rootReducer = combineReducers({
  LandingPageReducer,
  TimeManagementReducer,
  ClientMatterInquiryBalancesReducer,
  AuthReducer,
  AppReducer: appReducer,
});

export default rootReducer;
