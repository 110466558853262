import { takeLatest, put, select } from "redux-saga/effects";
import { PRE_POPULATE_REQUIRED_FIELDS, SUBMIT_HANDLE } from "../../constants/ClientMatterInquiry/balances";
import { conflictSearchApi } from "../../services/conflictSearchApi";
import commonUtils, { config } from "../../../utils/commonUtils";
import { getExistingMattersSucceeded, getSubClientsSucceeded, setCMInquiryLoading, setDisplayWidgetSection, setWidgetDataSuccess, updateEditedField } from "../../actions/ClientMatterInquiry/balancesAction";

export function* prePopulateSubClientOrMatter({ clientId }) {
    try {
        yield put(setCMInquiryLoading(true));
        const { balancesFields, } = yield select((state) => state.ClientMatterInquiryBalancesReducer);
        const { userID, delegateId } = yield select((state) => state.TimeManagementReducer);
        if (clientId) {
            let subClientId;
            //SubClients
            try {
                const response = yield conflictSearchApi.getSubClients(
                    clientId,
                    "",
                    0,
                    userID,
                    delegateId
                );
                if (response && response.success && response.subClients?.length === 1) {
                    const latestSubClients = commonUtils.convertToDropdownFormat(
                        response.subClients,
                        "sub_client_name",
                        "sub_client_id",
                        true,
                        "sub_client_no"
                    );
                    yield put(getSubClientsSucceeded(latestSubClients));
                    const item = latestSubClients[0];
                    subClientId = item.id;
                    yield put(
                        updateEditedField("subClient", {
                            id: item.id,
                            label: item.sub_client_name,
                            number: item.sub_client_no,
                            value: item.value,
                            ...item,
                        })
                    );
                } else {
                    yield put(updateEditedField("subClient", {}));
                }
            } catch (e) {
                //Get SubClients failed
            }
        } else {
            yield put(getSubClientsSucceeded([]));
            yield put(getExistingMattersSucceeded([]));
        }
        yield put(setCMInquiryLoading(false));
    } catch (e) {
        yield put(setCMInquiryLoading(false));
    }
}

export function* getWidgetData() {
    try {
        yield put(setCMInquiryLoading(true));
        const { balancesFields, } = yield select(
            (state) => state.ClientMatterInquiryBalancesReducer
        );
        const response = yield conflictSearchApi.getBalancesWidgetData(balancesFields);
        if (response && response.success && response.data?.length > 0) {
            yield put(setWidgetDataSuccess(response.data[0]))
            yield put(setDisplayWidgetSection(true))
        }
        yield put(setCMInquiryLoading(false));
    } catch (error) {
        yield put(setCMInquiryLoading(false));
    }
}

export function* balancesSaga() {
    yield takeLatest(PRE_POPULATE_REQUIRED_FIELDS, prePopulateSubClientOrMatter);
    yield takeLatest(SUBMIT_HANDLE, getWidgetData);
}

export default balancesSaga;
