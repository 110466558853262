import {
  SET_ERROR_MESSAGE,
  SET_BREADCRUMB_DATA,
  SET_TAB_ITEMS_DATA,
  SET_PERMISSIONS_DATA,
} from "../constants/appAction";

export const setErrorMessage = (payload) => {
  return {
    type: SET_ERROR_MESSAGE,
    data: payload,
  };
};

export const setBreadCrumb = (data) => {
  return {
    type: SET_BREADCRUMB_DATA,
    data,
  };
};

export const setTabIemsData = (bCrumbData, tabItemsData) => {
  return {
    type: SET_TAB_ITEMS_DATA,
    bCrumbData,
    tabItemsData,
  };
};

export const setPermissionsData = (data) => {
  return {
    type: SET_PERMISSIONS_DATA,
    data,
  };
};