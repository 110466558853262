import React from "react";
import "react-app-polyfill/stable";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import store from "./redux/store/store";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-table/css/react-bootstrap-table.css";
import "./styles/index.scss";

function Root() {
  return (
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  );
}

export default Root;
