export const IS_LOADING = "isLoading";
export const FETCH_ITEMS_REQUEST = "FETCH_ITEMS_REQUEST";
export const FETCH_ITEMS_SUCCESS = "FETCH_ITEMS_SUCCESS";
export const FETCH_ITEMS_FAILURE = "FETCH_ITEMS_FAILURE";

export const SET_SELECTED_TIMEKEEPER = "SET_SELECTED_TIMEKEEPER";
export const SET_AUTH_DETAILS = "SET_AUTH_DETAILS";
export const RESET_AUTH_DETAILS = "RESET_AUTH_DETAILS";
export const SET_ROUNDING_METHOD = "SET_ROUNDING_METHOD";
export const SET_FEATUREID = "SET_FEATUREID";

//Global Api's
export const USER_DELEGATES = "USER_DELEGATES"
export const USER_PREFERENCE = "USER_PREFERENCE"
export const USER_GET_PERMISSION = "USER_GET_PERMISSION"

export const FETCH_CLIENTITEMS_REQUEST = "FETCH_CLIENTITEMS_REQUEST";
export const FETCH_CLIENTITEMS_SUCCESS = "FETCH_CLIENTITEMS_SUCCESS";
export const FETCH_CLIENTITEMS_FAILURE = "FETCH_CLIENTITEMS_FAILURE";

export const FETCH_TIMEKEEPERLIST_REQUEST = "FETCH_TIMEKEEPERLIST_REQUEST";
export const FETCH_TIMEKEEPERLIST_SUCCESS = "FETCH_TIMEKEEPERLIST_SUCCESS";
export const FETCH_TIMEKEEPERLIST_FAILURE = "FETCH_TIMEKEEPERLIST_FAILURE";

export const SET_DURATION_TOGGLE = "SET_DURATION_TOGGLE";
export const SET_FEATURE = "SET_FEATURE";
export const FETCH_ALL_RESTRICTED_WORDS_SUCCESS = "FETCH_ALL_RESTRICTED_WORDS_SUCCESS";
export const FETCH_ALL_RESTRICTED_WORDS_FAILURE = "FETCH_ALL_RESTRICTED_WORDS_FAILURE";
export const SET_DELEGATE_FEATURE = 'SET_DELEGATE_FEATURE';