import {
  FETCH_CLIENTITEMS_REQUEST,
  FETCH_CLIENTITEMS_SUCCESS,
  FETCH_CLIENTITEMS_FAILURE,
  FETCH_TIMEKEEPERLIST_SUCCESS,
  FETCH_TIMEKEEPERLIST_REQUEST,
  SET_SELECTED_TIMEKEEPER,
  SET_ROUNDING_METHOD,
  SET_FEATURE,
  FETCH_ALL_RESTRICTED_WORDS_SUCCESS,
} from "../../actions/ActionType";

import { initialState } from "../initialstate";
const TimeManagementReducer = (state = initialState.TimeManagementReducer, action) => {
  switch (action.type) {
    case FETCH_CLIENTITEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CLIENTITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        clientitems: action.data,
        error: "",
      };
    case FETCH_CLIENTITEMS_FAILURE:
      return {
        ...state,
        loading: false,
        clientitems: [],
        error: action.data,
      };

    case FETCH_TIMEKEEPERLIST_REQUEST:
      return {
        ...state,
        loadingTimeKeeper: true,
      };
    case FETCH_TIMEKEEPERLIST_SUCCESS:
      return {
        ...state,
        loadingTimeKeeper: false,
        timeKeeperList: action.data,
        userID: action.data && action.data[0].userDelegateId,
        error: "",
      };
    case SET_SELECTED_TIMEKEEPER:
      return {
        ...state,
        delegateId: action.data.delegateId,
        dateFormat: action.data.dateFormat,
        attorneyCode: action.data.attorneyCode,
        userDelegateRoundingMethod: action.data.userDelegateRoundingMethod,
        timekeeper: action.data.timekeeper,
      };
    case SET_ROUNDING_METHOD:
      return {
        ...state,
        userDelegateRoundingMethod: action.data.userDelegateRoundingMethod,
      };
    case SET_FEATURE:
      return {
        ...state,
        timeKeeperFeatureName: action.data.FeatureName,
        timeKeeperFeatureId: action.data.FeatureId,
      };
    case FETCH_ALL_RESTRICTED_WORDS_SUCCESS:
      return {
        ...state,
        allRestrictedWords: action.data.restrictedWord,
      };
    default:
      return state;
  }
};
export default TimeManagementReducer;
