import commonUtils from "../../utils/commonUtils";
import { GET, PUT, POST } from "./axiosclient";


export const conflictscreenApi = {
  getCMLiterals: () => {
    return GET('main/api/v1/literals', "CM", {})
  },

  //STANCES API CALLS
  getStances: (pageNumber, queryString = "", headers) => {
    return GET(`stance/api/v1/stance?page=${pageNumber}${queryString}`, "CM", {}, headers);
  },
  getStanceType: () => {
    return GET('stance/api/v1/stancetypes', "CM", {})
  },
  getStanceList : () => {
    return GET ('stance/api/v1/lmsstance?page=1' , "CM", {})
  },
  addNewStance: (request_body) => {
    return POST('stance/api/v1/stance', 'CM', request_body)
  },
  editStanceRow: (id, request_body) => {
    return PUT('stance/api/v1/stance/' + id, 'CM', request_body)
  },

  //PARTY TYPE API CALLS
  getPartyTypes: (user_id, delegate_user_id, pageNumber, queryString = "",headers) => {
    const baseURL = `partytype/api/v1/partytypes?page=${pageNumber}${queryString}`;
    return GET(baseURL, "CM", {},headers);
  },
  addPartyType: (user_id, delegate_user_id, request_body) => {
    return POST(`partytype/api/v1/partytypes?${commonUtils.appendDelegateUser(user_id, delegate_user_id)}`, "CM", request_body)
  },
  updatePartyType: (id, user_id, delegate_user_id, request_body) => {
    return PUT(`partytype/api/v1/partytypes/${id}?${commonUtils.appendDelegateUser(user_id, delegate_user_id)}`, "CM", request_body)
  },
  // ASSOCIATION API CALLS
  getAreaoFlawListForDropdown: () => {
    return GET('areaoflaw/api/v1/areaoflaw?status=active', "CM", {})
  },
  getPartyTypeforDropdown: () => {
    return GET('partytype/api/v1/partytypes?status=active', "CM", {})
  },
  getAssociations: (user_id, delegate_user_id, pageNumber, queryString = "",headers) => {
    const baseURL = `association/api/v1/associations?page=${pageNumber}${queryString}`;
    return GET(baseURL, "CM", {},headers)
  },
  addnewAssociation: (user_id, delegate_user_id, request_body) => {
    return POST(`association/api/v1/associations?${commonUtils.appendDelegateUser(user_id, delegate_user_id)}`, 'CM', request_body)
  },
  editAssociationRow: (id, user_id, delegate_user_id, request_body) => {
    return PUT(`association/api/v1/associations/${id}?${commonUtils.appendDelegateUser(user_id, delegate_user_id)}`, 'CM', request_body)
  },


  //CONFLICT ACTIONS API CALLS
  getConflictActions: (user_id, delegate_user_id, pageNumber, queryString = "", headers) => {
    const baseURL = `action/api/v1/actions?page=${pageNumber}${queryString}`;
    return GET(baseURL, "CM", {}, headers);
  },
  getActionTypeLOV: () => {
    return GET('action/api/v1/actiontypes', "CM", {})
  },
  addnewConflictActions: (user_id, delegate_user_id, request_body) => {
    return POST(`action/api/v1/actions?${commonUtils.appendDelegateUser(user_id, delegate_user_id)}`, 'CM', request_body)
  },
  editConflictActions: (request_body, id, user_id, delegate_user_id,) => {
    return PUT(`action/api/v1/actions/${id}?${commonUtils.appendDelegateUser(user_id, delegate_user_id)}`, 'CM', request_body)
  },

  //SEARCH ENGINE - SEARCH ENTITIES
  getSearchEngineDatabasesLOV: (user_id, delegate_user_id, headers) => {
    const baseURL = `searchsetting/api/v1/entitytypes?${commonUtils.appendDelegateUser(user_id, delegate_user_id)}`;
    return GET(baseURL, 'CM', {},headers)
  },
  getAllSearchSettings: (user_id, delegate_user_id, headers) => {
    const baseURL = `searchsetting/api/v1/searchsettings?${commonUtils.appendDelegateUser(user_id, delegate_user_id)}`;
    return GET(baseURL, 'CM', {}, headers);
  },
  getSearchEntitesBasedOnAOL: (id) => {
    return GET('searchsetting/api/v1/searchsettings?area_of_law_id=' + id, 'CM', {})
  },
  saveSearchSettings: (user_id, delegate_user_id, request_body) => {
    return POST(`searchsetting/api/v1/searchsettings?${commonUtils.appendDelegateUser(user_id, delegate_user_id)}`, 'CM', request_body)
  },

  //Serach engine Synonyms APi call
  getSearchEngineSynonyms: (user_id, delegate_user_id, pageNumber, queryString = "", headers) => {
    const baseURL = `synonym/api/v1/synonyms?page=${pageNumber}${queryString}`;
    return GET(baseURL, "CM", {} , headers);
  },
  addSynonym: (user_id, delegate_user_id, request_body) => {
    return POST(`synonym/api/v1/synonyms?${commonUtils.appendDelegateUser(user_id, delegate_user_id)}`, "CM", request_body)
  },
  updateSynonym: (id, user_id, delegate_user_id, request_body) => {
    return PUT(`synonym/api/v1/synonyms/${id}?${commonUtils.appendDelegateUser(user_id, delegate_user_id)}`, "CM", request_body)
  },

  //Search Engine Word and Char Exclusion API Calls
  getSearchEngineExclusion: (user_id, delegate_user_id, pageNumber, queryString = "", headers) => {
    const baseURL = `exclusion/api/v1/exclusions?page=${pageNumber}${queryString}`;
    return GET(baseURL, "CM", {}, headers)
  },
  addExclusion: (request_body, user_id, delegate_user_id) => {
    return POST(`exclusion/api/v1/exclusions?${commonUtils.appendDelegateUser(user_id, delegate_user_id)}`, "CM", request_body)
  },
  updateExclusion: (id, request_body, user_id, delegate_user_id) => {
    return PUT(`exclusion/api/v1/exclusions/${id}?${commonUtils.appendDelegateUser(user_id, delegate_user_id)}`, "CM", request_body)
  },

  //Search Engine Formal Search Parameters API Calls
  getParamsLastUpdateDropdownLOV: () => {
    return GET('formalsearchoption/api/v1/versions', "CM", {})
  },
  getAllFormalSearchParamsDefaultValue: (user_id, delegate_user_id, headers) => {
    return GET(`formalsearchoption/api/v1/searchparameters`, "CM", {}, headers)
  },
  saveFormalSearchParams: (request_body, user_id, delegate_user_id) => {
    return POST(`formalsearchoption/api/v1/searchparameters?${commonUtils.appendDelegateUser(user_id, delegate_user_id)}`, "CM", request_body)
  },
  getAllFormalSearchParamsValueForSelectedParamLastUpdate: (area_of_law_id, param_last_update_id) => {
    return GET(`formalsearchoption/api/v1/searchparameters?area_of_law_id=${area_of_law_id}&last_update_id=${param_last_update_id}`)
  },
  getAllFormalSearchParamsValueForSelectedSettingsFor: (selected_settings_for_id) => {
    return GET(`formalsearchoption/api/v1/searchparameters?area_of_law_id=${selected_settings_for_id}`)
  },

}
