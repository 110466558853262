import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./App.scss";
import { Toast } from "primereact/toast";
import Header from "./components/Common/Header/Header";
import { TabBarComponent, Footer, UserAndDelegates } from "@attorneyflow-mf/styleguide";
import Menuitems from "./components/Common/Menuitems/Menuitems";
import { useSelector, useDispatch } from "react-redux";
import storage from "./utils/storage";
import { globalApi } from "./redux/services/endpoints";
import { TOOLTIPS_KEYS, TOOLTIPS_VALUES } from "../src/Config/vars";
import { setAuthDetails } from "./redux/actions/AuthAction";
import permissionsState from "./redux/store/ClientMatterRolesPermissions.ts";
import Spinner from "./components/Common/Spinner/Spinner";
import { fetchItemsLandingPage } from "./redux/actions/LandingPageActions";
import { AuthService } from "./redux/services/authService";
import { resetAuthDetails } from "./redux/actions/AuthAction";
import { userApi } from "./redux/services/userapis";
import ProtectedRoutes from "./Config/ProtectedRoutes";
import commonUtils, { config } from "./utils/commonUtils";
import ConfirmationModal from "./components/Common/Confirmation/ConfirmationModal";
import { setPermissionsData } from "./redux/actions/commonAction";
import {
  fetchUserPreferenceDetails,
  setErrorMessage,
  setSubClientStatus,
  setUnsavedStatus,
  setUserDetails,
  setUserTimezone,
} from "./redux/actions/AppAction";
import InactivityDetection from "./components/Common/InactivityDetection";
import userHistory from "./redux/store/globalStore";
import {
  fetchTimeKeeperListSuccess,
  setDurationToggle,
  setSelectedTimeKeeperStoreAction,
} from "./redux/actions/TimeManagementAction";
import "./components/Common/UserAndDelegates/UserAndDelegates.scss";
import { GetSetDelegateData, GetSetPermissionsData, GetSetLiteralData } from "@attorneyflow-mf/styleguide";

import jwtDecoder from "jwt-decode";
import { env_name } from "./config";

window.version = 1;

const App = (props) => {
  const toast = useRef(null);
  const toast2 = useRef(null);
  const {
    apiResponse,
    showMessage,
    activeTab,
    tabItems,
    breadcrumbData,
    unsavedChangesFlag,
    preferences,
    permissionsData,
    helpLink,
    firstName,
    lastName,
    userName,
  } = useSelector(({ AppReducer }) => ({
    apiResponse: AppReducer.apiResponse,
    showMessage: AppReducer.showMessage,
    activeTab: AppReducer.activeTab,
    tabItems: AppReducer.tabItems,
    breadcrumbData: AppReducer.breadcrumbData,
    unsavedChangesFlag: AppReducer.unsavedChangesFlag,
    preferences: AppReducer.preferences,
    permissionsData: AppReducer.permissionsData,
    helpLink: AppReducer.helpLink,
    firstName: AppReducer.firstName,
    lastName: AppReducer.lastName,
    userName: AppReducer.userName,
  }));

  const Menuitemdata =
    useSelector((state) => state.LandingPageReducer.items) || [];
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Need to change it to false
  const [MountAPP, setMountAPP] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [burgerClicked, setBurgerClicked] = useState(false);
  const AuthReducer = useSelector((state) => state.AuthReducer);
  const [showDailog, setShowDailog] = useState(false);
  const [dailogMessage, setDailogMessage] = useState("");
  const [showDailogFor, setShowDailogFor] = useState("");
  const [codeFromAPI, setCodeFromAPI] = useState([]);
  const [url, seturl] = useState("");
  const [activeItem, setActiveItem] = useState(tabItems ? tabItems[0] : null);
  const [timeoutMinutes, setTimeoutMinutes] = useState(
    config.TIMEOUT_MINUTES_SHORT
  );
  const [countDownTime, setCountDownTime] = useState(
    config.COUTDOWN_TIME_MINUTES_SHORT * 60 * 1000
  );

  let history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const active_Tab = tabItems.find(
      (x) =>
        x.path.toLowerCase() === window.location.pathname.toLowerCase() ||
        x.checkpath.toLowerCase() === window.location.pathname.toLowerCase()
    );
    setActiveItem(active_Tab);
  }, [tabItems, window.location.pathname]);

  const [bcData, setbcData] = useState(breadcrumbData);
  useEffect(() => {
    const items = breadcrumbData.map((pathObj) => {
      return { label: getCode(pathObj) };
    });
    setbcData(items);
  }, [breadcrumbData]);
  useEffect(() => {
    setMessage();
  }, [dispatch, apiResponse]);

  const setMessage = useCallback(
    function () {
      if (
        apiResponse &&
        apiResponse.errorCode !== undefined &&
        apiResponse.errorCode !== 401 &&
        localStorage.getItem("userRolesUpdated") === null
      ) {
        toast &&
          toast.current &&
          toast.current.show({
            severity: apiResponse.severity,
            detail: apiResponse.message,
            life: apiResponse.life || 5000,
          });
        dispatch(setErrorMessage({}));
      }
    },
    [dispatch, apiResponse]
  );

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(
        setAuthDetails(
          { username: localStorage.getItem("cognito_username") },
          localStorage.getItem("cognito_email")
        )
      );
      dispatch(fetchUserPreferenceDetails());
    }
  }, [localStorage.getItem("token")]);

  useEffect(() => {
    dispatch(fetchItemsLandingPage());
  }, [firstName])

  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("token").length > 1 &&
      sessionStorage.getItem("token") &&
      sessionStorage.getItem("token").length > 1
    ) {
      dispatch(
        setAuthDetails(
          { username: localStorage.getItem("cognito_username") },
          localStorage.getItem("cognito_email")
        )
      );
    } else {
      dispatch(resetAuthDetails());
    }
  }, [sessionStorage.getItem("token")]);

  useEffect(() => {
    if (!AuthReducer.isAuth) {
      setIsLoggedIn(false);
    } else {
      const token = localStorage.getItem("token");
      const decodedToken = jwtDecoder(token);
      if (userName !== "") {
        pendo.initialize({
          visitor: {
            id: localStorage.getItem("cognito_username"),// logged-in user ID
            email: localStorage.getItem("cognito_email"),// logged-in user email
            full_name: firstName + " " + lastName,
            env: env_name,
            device: 'web'
            // email:        // Recommended if using Pendo Feedback, or NPS Email
            // full_name:    // Recommended if using Pendo Feedback
            // role:         // Optional

            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
          },

          account: {
            id: decodedToken['custom:clientid'] // Required if using Pendo Feedback
            //     // name:         // Optional
            //     // is_paying:    // Recommended if using Pendo Feedback
            //     // monthly_value:// Recommended if using Pendo Feedback
            //     // planLevel:    // Optional
            //     // planPrice:    // Optional
            //     // creationDate: // Optional

            //     // You can add any additional account level key-values here,
            //     // as long as it's not one of the above reserved names.
          }
        });
      }
    }
  }, [AuthReducer.isAuth, firstName, lastName, userName]);

  const getCode = (key) => {
    return codeFromAPI && codeFromAPI[key] ? codeFromAPI[key] : key;
  };

  useEffect(() => {
    if (!dataLoading && !MountAPP && !isLoggedIn) {
      setDataLoading(true);
      const delegatecached = GetSetDelegateData();
      if (!delegatecached) {
        globalApi.delegates().then((res) => {
          window.delegates = res;
          GetSetDelegateData(res);
          if (!Array.isArray(res)) {
            if (res && res.userDelegates && res.userDelegates.length > 0) {
              setLoggedInUserTK(res.userDelegates[0]?.timekeeper);
              userApi
                .getPermissions(res.userDelegates[0].userDelegateId)
                .then((d) => {
                  let fieldnames = [];
                  d.roles.forEach((data, index) => {
                    if (data.ui_display_config !== null) {
                      data.ui_display_config.uiPageJson.permissions.components.forEach(
                        (fields, i) => {
                          fieldnames.push(fields);
                        }
                      );
                    }
                  });
                  setMountAPP(true);
                  setIsLoggedIn(true);
                  permissionsState.setState(fieldnames);
                  GetSetPermissionsData(fieldnames);
                  dispatch(setPermissionsData(fieldnames));
                });
              userApi.getUserDetails().then((response) => {
                dispatch(setUserTimezone(response.userData.user.user_timezone));
                dispatch(setUserDetails({
                  firstName: response.userData.user.firstname,
                  lastName: response.userData.user.lastname,
                  userName: response.userData.user.username,
                }))
              });
            }
          }
          setMountAPP(true);
          setDataLoading(false);
        });
      } else {
        window.delegates = delegatecached;
        setLoggedInUserTK(delegatecached.userDelegates[0]?.timekeeper);
        const getPermissions = GetSetPermissionsData();
        setMountAPP(true);
        setIsLoggedIn(true);
        permissionsState.setState(getPermissions);
        dispatch(setPermissionsData(getPermissions));
        setDataLoading(false);
        userApi.getUserDetails().then((response) => {
          dispatch(setUserTimezone(response.userData.user.user_timezone));
        });
      }
    }
  }, [isLoggedIn]);
  const dispatchURL = async () => {
    seturl(URLforRedirection);
    const value = JSON.parse(sessionStorage.getItem("selectedTimeKeeper"));
    const findPreferenceforAddTime = window.delegates.preferences.find(
      (x) => x.featureId === 1
    );
    let postData = {
      userDelegateId: null,
      param_key: "DELEGATE_FEATURE",
      featureName: ["MG-Practice-MD-TM-FE-AddTime-MngTime"],
      featureId: [1],
    };
    let methodType = "post";
    if (!value && findPreferenceforAddTime) {
      postData.userDelegateId = findPreferenceforAddTime.userDelegateId;
      methodType = "delete";
    } else if (!value && !findPreferenceforAddTime) {
      sessionStorage.setItem("navigated-from", window.location.pathname);
      sessionStorage.setItem("show-add-time-popup", true);
      handleRedirect(URLforRedirection);
    } else if (value) {
      postData.userDelegateId = value.userDelegateId;
      methodType = "post";
    }
    if (postData.userDelegateId) {
      await globalApi
        .preferences(postData, methodType)
        .then((res) => {
          return;
        })
        .catch((error) => {
          dispatch(refreshStoreOnError({ refresh: new Date() }));
          console.log(error);
        })
        .finally(() => {
          sessionStorage.setItem("navigated-from", window.location.pathname);
          sessionStorage.setItem("show-add-time-popup", true);
          handleRedirect(URLforRedirection);
        });
    }
  };
  const loadAppContent = (sessionExists) => {
    if (sessionExists) {
      return (
        <>
          <InactivityDetection
            timeoutMinutes={timeoutMinutes}
            logOutHandler={inActivityLogOutHandler}
            countDownTime={countDownTime}
            configuration={{
              TIMEOUT_MINUTES_SHORT: config.TIMEOUT_MINUTES_SHORT,
              TIMEOUT_MINUTES_LONG: config.TIMEOUT_MINUTES_LONG,
              COUTDOWN_TIME_MINUTES_SHORT: config.COUTDOWN_TIME_MINUTES_SHORT,
              COUTDOWN_TIME_MINUTES_LONG: config.COUTDOWN_TIME_MINUTES_LONG,
              IDLE_SETTING_MINUTES_SHORT: config.IDLE_SETTING_MINUTES_SHORT,
              IDLE_SETTING_MINUTES_LONG: config.IDLE_SETTING_MINUTES_LONG,
            }}
          />

          <div className="page-layout-cmi">
            <div className="af-header">
              <div className="header-comp">
                <Header Redirect={handleRedirect} />
              </div>
              <div className="af-header-icons">
                <UserAndDelegates
                  delegateData={window.delegates}
                  timeKeeperFeatureName={timeKeeperFeatureName}
                  userDelegateRoundingMethod={userDelegateRoundingMethod}
                  placeholder={placeholder}
                  showButton={showButton}
                  timekeeper={timekeeper}
                  display={display}
                  timeKeeperListData={timeKeeperListData}
                  menuItem={menuItem}
                  onTimeKeeperChange={onTimeKeeperChange}
                  toggleButton={toggleButton}
                  setSelectedTimeKeeperStoreAction={updateTimeKeeperStoreAction}
                  getCode={commonUtils.getIntlMessage}
                  selectedTimekeeper={selectedTimekeeper}
                  iconTimekeeper={iconTimekeeper}
                  delegateId={delegateId}
                  dateFormat={dateFormat}
                  attorneyCode={attorneyCode}
                  tooltips={tooltips}
                  toolTipkey={TOOLTIPS_KEYS.TOOLTIP_ACCOUNT_MENU}
                  location={location}
                  setDisplay={setDisplay}
                  Redirect={handleRedirect}
                  dispatchURL={dispatchURL}
                  userPermission={permissionsData}
                  URLforRedirection={URLforRedirection}
                  loggedInUserTK={loggedInUserTK}
                ></UserAndDelegates>
              </div>
            </div>
            <TabBarComponent
              handleBurgerClick={handleBurgerClick}
              burgerClicked={burgerClicked}
              activeTab={activeTab}
              tabItems={tabItems}
              breadcrumb={bcData}
              Redirect={handleRedirect}
              activeItem={activeItem}
              helpurl={helpLink}
              getCode={getCode}
            />

            {burgerClicked ? (
              <Menuitems
                itemdata={Menuitemdata}
                handlesignout={handleSignout}
                Redirect={handleRedirect}
                getCode={getCode}
              />
            ) : (
              <div className="client-matter-inquiry-container">
                <ProtectedRoutes
                  menuData={Menuitemdata}
                  permissionData={permissionsData}
                />
              </div>
            )}
          </div>
          <Footer />
        </>
      );
    } else {
      //If Session not exists go back to login page.
      history.push("/");
    }
  };

  useEffect(() => {
    let literals = storage.getObject("literals");
    seturl("");
    if (literals) {
      setCodeFromAPI(literals);
      GetSetLiteralData(literals);
      setTooltips({
        [TOOLTIPS_KEYS.TOOLTIP_ACCOUNT_INFO]:
          literals[TOOLTIPS_KEYS.TOOLTIP_ACCOUNT_INFO],
      });
    } else {
      globalApi.languageMessages().then((res) => {
        if (res && res.literals) {
          storage.setObject("literals", res.literals, true);
          setCodeFromAPI(res.literals);
          GetSetLiteralData(res.literals);
        }
      });
    }
  }, []);

  const handleBurgerClick = () => {
    setBurgerClicked(!burgerClicked);
  };

  const commonLogoutHandler = () => {
    dispatch(resetAuthDetails());
    sessionStorage.removeItem("token");
    storage.clearLocalStorge();
    window.location.href = window.location.origin;
  };

  const inActivityLogOutHandler = () => {
    commonLogoutHandler();
    localStorage.setItem("sessionExpired", false);
    localStorage.setItem("Logout_cause", "Inactivity timed out");
  };

  const handleSignout = () => {
    AuthService.signOut(
      AuthReducer.email,
      localStorage.getItem("refreshToken")
    ).then(() => {
      commonLogoutHandler();
    });
  };
  const callBackFromDailog = ({ callBackFrom, newVal }) => {
    if (newVal) {
      //STAY is clicked
    } else if (callBackFrom === "UnsavedChanges") {
      //LEAVE is clicked
      dispatch(setUnsavedStatus(false));
      changePagewhenRedirected(url);
    }
    setShowDailog(false);
  };
  const changePagewhenRedirected = (URL) => {
    if (URL.toLowerCase().indexOf("logout") !== -1) {
      handleSignout();
      return;
    }
    if (URL.startsWith("https")) {
      let openInNewtab = URL.split("//")[1].split(".")[0];
      let tokenUrl = `${URL.indexOf("?") === -1 ? "?" : "&"
        }token=${localStorage.getItem(
          "token"
        )}&accesstoken=${localStorage.getItem(
          "accessToken"
        )}&refreshToken=${localStorage.getItem("refreshToken")}`;
      URL.indexOf("view.rippecloud.com") != -1
        ? window.open(URL.trim(), openInNewtab)
        : window.open(URL.trim() + tokenUrl, openInNewtab);
      setBurgerClicked(false);
    }
    else {
      setBurgerClicked(false);
      return history.push(URL);
    }
  };
  const handleRedirect = (urlPath) => {
    seturl(urlPath);
    if (unsavedChangesFlag) {
      setShowDailogFor("UnsavedChanges");
      setShowDailog(true);
      setDailogMessage(
        commonUtils.getIntlMessage(
          "YOU_HAVE_UNSAVED_CHANGES_ON_THE_SCREEN_IF_YOU_LEAVE_YOUR_CHANGES_WILL_BE_LOST_DO_YOU_WANT_TO_STAY"
        )
      );
      return;
    } else {
      changePagewhenRedirected(urlPath);
    }
  };

  //shows tost message whenever roles of users are updated and logs out user
  useEffect(() => {
    if (localStorage.getItem("userRolesUpdated")) {
      setTimeout(() => {
        localStorage.removeItem("token");
        setIsLoggedIn(false);
        commonLogoutHandler();
      }, 3000);

      toast2 &&
        toast2.current &&
        toast2.current.show({
          severity: "error",
          detail: commonUtils.getIntlMessage(
            "User is not authorized to access this resource with explicit deny"
          ),
          life: 7000,
        });
      localStorage.removeItem("userRolesUpdated");
    }
  }, [dispatch, localStorage.getItem("userRolesUpdated")]);

  //Common function to download the attachment with signed_url
  window.downloadAttachment = (download_url, docName, target = "_blank") => {
    if (download_url) {
      var a = window.document.createElement("a");
      a.href = download_url;
      a.download = docName || true;
      a.target = target;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  useEffect(() => {
    setSessionTimeOut(preferences.userSessiosnTimeout);
  }, [preferences.userSessiosnTimeout]);

  useEffect(() => {
    if (preferences.preferenceSettings) {
      let setting = preferences.preferenceSettings.find(
        (preference) => preference.param_key === "AUTOMATIC_SESSION_TIMEOUT"
      );
      setSessionTimeOut(setting && setting.param_value);
      let subClientSetting = preferences.preferenceSettings.find(
        (preference) => preference.param_key === "HAS_MULTIPLE_SUB_CLIENTS"
      );
      dispatch(setSubClientStatus(subClientSetting && subClientSetting.param_value));
    }
  }, [preferences.preferenceSettings]);

  const setSessionTimeOut = (timeOutSetting) => {
    if (timeOutSetting == config.IDLE_SETTING_MINUTES_SHORT) {
      console.log(" session timeout: 15 minutes");
      setTimeoutMinutes(config.TIMEOUT_MINUTES_SHORT);
      setCountDownTime(config.COUTDOWN_TIME_MINUTES_SHORT * 60 * 1000);
    } else if (timeOutSetting == config.IDLE_SETTING_MINUTES_LONG) {
      console.log(" session timeout: 12 hours");
      setTimeoutMinutes(config.TIMEOUT_MINUTES_LONG);
      setCountDownTime(config.COUTDOWN_TIME_MINUTES_LONG * 60 * 1000);
    }
  };

  ///Code from CM components ENDSSS *********************************
  /// Code for UserDelegates
  const timeKeeperFeatureName = useSelector(
    (state) => state.TimeManagementReducer.timeKeeperFeatureName
  );
  const timeKeeperFeatureId = useSelector(
    (state) => state.TimeManagementReducer.timeKeeperFeatureId
  );
  const [placeholder, setPlaceholder] = useState("");
  const [showButton, setShowButton] = useState(true);
  const [timekeeper, setTimekeeper] = useState(false);
  const [display, setDisplay] = useState(false);
  const [timeKeeperListData, setTimeKeeperListData] = useState();
  const [userDelegateRoundingMethod, setUserDelegateRoundingMethod] = useState(
    "NORMAL"
  );
  const [menuItem, setmenuItem] = useState([]);
  const [selectedTimekeeper, setSelectedTimekeeper] = useState(null);
  const [iconTimekeeper, setIconTimekeeper] = useState();
  const [delegateId, setDelegateId] = useState(userHistory.delegateId);
  const [dateFormat, setDateFormat] = useState("");
  const [attorneyCode, setAttorneyCode] = useState("");
  const [tooltips, setTooltips] = useState({
    [TOOLTIPS_KEYS.TOOLTIP_ACCOUNT_MENU]: [TOOLTIPS_VALUES.ACCOUNT_MENU],
  });
  const [menuUrl, setMenuUrl] = useState(null);

  const setSelectedTimekeeperHandler = (value) => {
    setSelectedTimekeeper(value);
    storage.setSessionStorageValue("selectedTimeKeeper", value);
  };
  useEffect(() => {
    if (menuUrl) {
      seturl(menuUrl);
      handleRedirect(menuUrl);
      setMenuUrl(null);
    }
  }, [menuUrl]);

  useEffect(() => {
    const menuItemsArr = [];
    Menuitemdata?.moduleGroups &&
      Menuitemdata.moduleGroups?.forEach((groupObj) => {
        if (groupObj.moduleGroupID === 6) {
          groupObj.modules.forEach((moduleObj) => {
            if (moduleObj.moduleID === 24) {
              menuItemsArr.push({
                label: commonUtils.getIntlMessage(moduleObj.moduleName),
                icon: moduleObj.moduleName,
                command: () => handleSignout(),
              });
            } else {
              menuItemsArr.push({
                label: commonUtils.getIntlMessage(moduleObj.moduleName),
                icon: moduleObj.moduleName,
                command: () => {
                  setMenuUrl(`${moduleObj.url}`);
                },
              });
            }
          });
        }
      });
    setmenuItem(menuItemsArr);
  }, [Menuitemdata, localStorage.getItem("_literals")]);

  const [loggedInUserTK, setLoggedInUserTK] = useState(false);
  const URLforRedirection = "/SureTime";
  useEffect(() => {
    setDisplay(false);
    setSelectedTimekeeperHandler(null);
    setShowButton(true);
    if (
      timeKeeperFeatureName === "MG-Account-MD-Preferences-FE-TimeMng" ||
      timeKeeperFeatureName === "MG-Account-MD-Delegates-FE-Delegates"
    ) {
      setPlaceholder("features cannot be delegated");
    } else {
      setPlaceholder("SELECT_A_USER");
    }
    let res = window.delegates;
    if (timeKeeperFeatureName !== "" && AuthReducer.isAuth) {
      globalApi
        .delegates()
        .then((responseDelegates) => {
          res = responseDelegates;
          window.delegates = responseDelegates;
          preferenceSet(responseDelegates);
          setLoggedInUserTK(responseDelegates?.userDelegates[0]?.timekeeper);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      preferenceSet(res);
    }
    res &&
      res.userDelegates &&
      res.userDelegates.length > 0 &&
      res.userDelegates[0].recordDuration &&
      dispatch(setDurationToggle(res.userDelegates[0].recordDuration));
  }, [timeKeeperFeatureName, AuthReducer.isAuth]);

  const preferenceSet = (res) => {
    setUserRoundingPreference(res);
    if (!Array.isArray(res)) {
      if (
        res &&
        res.userDelegates &&
        res.userDelegates !== null &&
        res.userDelegates.length > 0
      ) {
        res.userDelegates.forEach((element) => {
          element.userDelegateFullName =
            element.userDelegateFirstName + " " + element.userDelegateLastName;
          element.userDelegateFullNameCode =
            element.userDelegateLastName + ", " + element.userDelegateFirstName;
        });
        setTimeKeeperListData(res.userDelegates);
        dispatch(fetchTimeKeeperListSuccess(res.userDelegates));
      }
      let indexOfTimekeeper =
        res && res.preferences
          ? res.preferences.findIndex(
            (item) => item.featureName === timeKeeperFeatureName
          )
          : -1;

      if (res && res.preferences && indexOfTimekeeper !== -1) {
        res.preferences[indexOfTimekeeper].userDelegateFullName =
          res.preferences[indexOfTimekeeper].userDelegateFirstName +
          " " +
          res.preferences[indexOfTimekeeper].userDelegateLastName;
        res.preferences[indexOfTimekeeper].userDelegateFullNameCode =
          res.preferences[indexOfTimekeeper].userDelegateLastName +
          ", " +
          res.preferences[indexOfTimekeeper].userDelegateFirstName;
        setShowButton(false);
        setSelectedTimekeeperHandler(res.preferences[indexOfTimekeeper]);
        setIconTimekeeper(
          res.preferences[indexOfTimekeeper].userDelegateImageS3URL
        );
        setAttorneyCode(res.preferences[indexOfTimekeeper].attorneyCode);
        setDateFormat(
          res.preferences[indexOfTimekeeper].userDelegateDateFormat
        );
        setDelegateId(res.preferences[indexOfTimekeeper].userDelegateId);
        setTimekeeper(res.preferences[indexOfTimekeeper].timekeeper);
        setShowButton(false);
      } else {
        if (
          res &&
          res.userDelegates &&
          res.userDelegates !== null &&
          res.userDelegates.length > 0
        ) {
          setDelegateId(res.userDelegates[0].userDelegateId);
          setDateFormat(res.userDelegates[0].userDelegateDateFormat);
          setAttorneyCode(res.userDelegates[0].attorneyCode);
          setTimekeeper(res.userDelegates[0].timekeeper);
        }
      }
    }
  };

  /**
   * sets user rounding preference
   * @param {Object} res
   */
  const setUserRoundingPreference = (res) => {
    if (!Array.isArray(res)) {
      if (res && res.userDelegates && res.userDelegates.length > 0) {
        setUserDelegateRoundingMethod(
          res.userDelegates[0].userDelegateRoundingMethod
        );
      } else if (res && res.preferences && res.preferences.length > 0) {
        setUserDelegateRoundingMethod(
          res.preferences[0].userDelegateRoundingMethod
        );
      }
    }
  };

  const onTimeKeeperChange = (value, checkOnSelect) => {
    if (value.userDelegateFullNameCode === "null, null") {
      value.userDelegateFullNameCode = "";
    }

    setSelectedTimekeeperHandler(value);

    setDisplay(false);

    if (checkOnSelect) {
      setIconTimekeeper(value.userDelegateImageS3URL);
      setAttorneyCode(value.attorneyCode);
      setDateFormat(value.userDelegateDateFormat);
      setDelegateId(value.userDelegateId);
      setTimekeeper(value.timekeeper);
      let postData = {
        userDelegateId: value.userDelegateId,
        param_key: "DELEGATE_FEATURE",
        featureName: [value.featureName],
        featureId: [value.featureId],
      };
      invokePreferencesAPI(postData, "post");
    }
  };
  let invokePreferencesAPI = (postData, callMethod) => {
    globalApi
      .preferences(postData, callMethod)
      .then((res) => { })
      .catch((error) => {
        dispatch(refreshStoreOnError({ refresh: new Date() }));
        console.log(error);
      })
      .finally(() => { });
  };
  const toggleButton = () => {
    setShowButton(!showButton);
    if (showButton === false && selectedTimekeeper !== null) {
      setAttorneyCode(timeKeeperListData[0].attorneyCode);
      setDateFormat(timeKeeperListData[0].userDelegateDateFormat);
      setDelegateId(timeKeeperListData[0].userDelegateId);
      setTimekeeper(timeKeeperListData[0].timekeeper);
      //  setIsTimekeeper(timeKeeperListData[0].timekeeper);
      setUserDelegateRoundingMethod(
        timeKeeperListData[0].userDelegateRoundingMethod
      );
      setSelectedTimekeeperHandler(null);

      let postData = {
        userDelegateId: delegateId,
        param_key: "DELEGATE_FEATURE",
        featureName: [timeKeeperFeatureName],
        featureId: [timeKeeperFeatureId],
      };

      invokePreferencesAPI(postData, "delete");
      placeholder === "NO_USERS_AVAILABLE" && setPlaceholder("SELECT_A_USER");
    } else {
      let list = [];
      timeKeeperListData.forEach((item, index) => {
        if (index !== 0 && item.featureName === timeKeeperFeatureName) {
          list.push(item);
        }
      });
      if (list.length === 0) {
        placeholder === "SELECT_A_USER" && setPlaceholder("NO_USERS_AVAILABLE");
      }
    }
    setDisplay(true);
  };
  const updateTimeKeeperStoreAction = () => {
    dispatch(
      setSelectedTimeKeeperStoreAction(
        delegateId,
        dateFormat,
        attorneyCode,
        userDelegateRoundingMethod,
        timekeeper
      )
    );
  };

  //Code for UserDelegates
  return (
    <>
      <Toast ref={toast2} />
      <ConfirmationModal
        role={showDailogFor}
        show={showDailog}
        message={dailogMessage}
        parentCallback={callBackFromDailog}
        yesLabel={
          showDailogFor === "UnsavedChanges"
            ? `${commonUtils.getIntlMessage("STAY")}`
            : `${commonUtils.getIntlMessage("OK")}`
        }
        noLabel={
          showDailogFor === "UnsavedChanges"
            ? `${commonUtils.getIntlMessage("LEAVE")}`
            : commonUtils.getIntlMessage("CANCEL")
        }
      />
      {showMessage && <Toast ref={toast} />}
      <div className="page-wrapper">
        <div className="loader-class ">{dataLoading ? <Spinner /> : ""}</div>
        {isLoggedIn &&
          loadAppContent(
            localStorage.getItem("token") && sessionStorage.getItem("token")
          )}
      </div>
    </>
  );
};

export default App;
