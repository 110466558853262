// import { Fetch } from "./fetch";
import { GET, POST } from "./axiosclient"

import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserPool,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";
import { userApi } from "../services/userapis";
// import { accessUrl } from "../config";
import storage from "../../utils/storage";

function getCognitoUser() {
  const poolData = {
    UserPoolId: localStorage.getItem("UserPoolId"),
    ClientId: localStorage.getItem("ClientId"),
  };
  const userPool = new CognitoUserPool(poolData);
  const userData = {
    Username: localStorage.getItem("userEmail"),
    Pool: userPool,
  };
  return new CognitoUser(userData);
}

function getTokens(session) {
  return {
    accessToken: session.getAccessToken().getJwtToken(),
    idToken: session.getIdToken().getJwtToken(),
    refreshToken: session.getRefreshToken().getToken(),
  };
}

export const AuthService = {
  getAuthenticationCode: async (email) => {
    return POST(
      "/login/api/v1/reset",
      "TM",
      { username: email },
    );
  },

  resetPassword: async (email, authenticationCode, confirmPassword) => {
    return POST(
      "/login/api/v1/reset",
      "TM",
      {
        username: email,
        verification_code: authenticationCode,
        new_password: confirmPassword,
      },
    );
  },

  authenticate: async (Username, Password, callback) => {
    let postData = {
      email: Username,
    };
    return userApi.pool(postData).then((response) => {
      const poolData = {
        UserPoolId: response.poolData.UserPoolId,
        ClientId: response.poolData.ClientId,
      };
      localStorage.setItem("UserPoolId", response.poolData.UserPoolId);
      localStorage.setItem("ClientId", response.poolData.ClientId);
      const userPool = new CognitoUserPool(poolData);
      const userNamePool = new CognitoUser({ Username, Pool: userPool });
      const authDetails = new AuthenticationDetails({ Username, Password });
      userNamePool.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log("onSuccess:", data);
          callback(null, null, data, userPool.getCurrentUser(), Username);
          console.log(Username);
        },
        onFailure: (err) => {
          console.error("onFailure:", err);

          callback(null, err);
        },

        mfaRequired: function (data) {
          // MFA is required to complete user authentication.
          // Get the code from user and call
          callback(
            true,
            null,
            data,
            userPool.getCurrentUser(),
            Username,
            null,
            null,
            userNamePool
          );
          // cognitoUser.sendMFACode(mfaCode)
        },

        newPasswordRequired: function (userAttributes, requiredAttributes) {
          // User was signed up by an admin and must provide new
          // password and required attributes, if any, to complete
          // authentication.

          // the api doesn't accept this field back
          // delete userAttributes.email_verified;
          // userNamePool.completeNewPasswordChallenge("Test1234", userAttributes , this);
          callback(
            null,
            null,
            null,
            userPool.getCurrentUser(),
            null,
            userAttributes,
            requiredAttributes,
            userNamePool
          );
          // store userAttributes on global variable
          // sessionUserAttributes = userAttributes;
        },
      });
    });
  },

  handleMfaLogin: (mfaCode, userNamePool, callback) => {
    userNamePool.sendMFACode(mfaCode, {
      onSuccess: (res) => {
        callback(res);
      },
      onFailure: (err) => {
        callback(null, err);
      },
    });
  },

  handleNewPassword: (email, confirmPassword, password) => {
    return POST(
      "/login/api/v1/reset",
      "TM",
      {
        username: email,
        new_password: confirmPassword,
        old_password: password,
        forceReset: true,
      },
    );
  },
  postLogin: async (email, loginStatus) => {
    return POST(
      "/login/api/v1/postLogin",
      "TM",
      {
        email: email,
        loginStatus: loginStatus
      },
    );
  },

  signOut: async (email, refreshToken) => {
    let postData = {
      email: email,
      refreshToken: refreshToken,
    };

    return userApi.logout(postData);
  },
  checkTokenExpiration: () => {
    return new Promise(function (resolve) {
      if (!localStorage.getItem("idTokenExp")) {
        resolve({ idToken: "", accessToken: "" });
        return;
      }
      let now = Math.floor(new Date() / 1000);
      //if (cachedSession.isValid ()) { //Can use if accessToken is used instead of idToken   
      //It will refresh token 20 second before expiration time     
      if ((localStorage.getItem("idTokenExp") && localStorage.getItem("idTokenExp") - now > 60) &&
        (localStorage.getItem("accessTokenExp") && localStorage.getItem("accessTokenExp") - now > 60)
      ) { 
        resolve({ idToken: localStorage.getItem("token"), accessToken: localStorage.getItem("accessToken") });
        return;
      } else {
        const RefreshToken = new CognitoRefreshToken({
          RefreshToken: localStorage.getItem("refreshToken"),
        });
        const cognitoUser = getCognitoUser();
        cognitoUser.refreshSession(RefreshToken, (err, session) => {
          if (err) {
            //forces user to relogin as Refresh Token expired
            localStorage.removeItem("token");
            localStorage.removeItem("accessToken");
            localStorage.clear();
            localStorage.setItem("Logout_cause", "Refresh token failed");
            window.location.reload();
            throw err;
          }
          const newTokens = getTokens(session);
          if (newTokens) {
            localStorage.setItem("token", newTokens.idToken);
            localStorage.setItem("accessToken", newTokens.accessToken);
            localStorage.setItem("idTokenExp", session.idToken.payload.exp);
            localStorage.setItem("accessTokenExp", session.accessToken.payload.exp)
            storage.setCookie("AF_UserAuthTokenCookie", newTokens.idToken);
            storage.setCookie("AF_UserAccessTokenCookie", newTokens.accessToken);
            console.log("token refreshed");
            resolve({ idToken: newTokens.idToken, accessToken: newTokens.accessToken });
          } else {
            resolve({ idToken: localStorage.getItem("token"), accessToken: localStorage.getItem("accessToken") });
          }
        });
      }
    });
  },
  getPresinedUrlForAWSTranscribe: async (lang = "en-US") => {
    return GET(
      `/utils/api/v1/transcript?lc=${lang}`,
      "TM",
    );
  },
};
