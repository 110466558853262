import { call, takeLatest, put } from "redux-saga/effects";
import { FETCH_ITEMS_REQUEST } from "../actions/ActionType";
import { globalApi } from "../services/endpoints";
import { fetchMenuSuccess } from "../actions/LandingPageActions";
import { setErrorMessage } from "../actions/commonAction";
import { GetSetMenuData } from "@attorneyflow-mf/styleguide";

export function* globalSaga() {
  yield takeLatest(FETCH_ITEMS_REQUEST, fetchMenuItems);
}

export function* fetchMenuItems() {
  try {
    let menuDataCached = GetSetMenuData();
    if (!menuDataCached) {
      const { landingPageData } = yield call(globalApi.menus);
      GetSetMenuData([{ landingPageData: landingPageData }]);
      menuDataCached = [{ landingPageData: landingPageData }];
    }
    yield put(fetchMenuSuccess(menuDataCached[0].landingPageData));
  } catch (e) {
    console.log("into error of saga", e.message);
    yield put(setErrorMessage(e.message));
  }
}

export default globalSaga;
