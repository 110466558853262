import {
    GET_EXISTING_CLIENTS_SUCCEEDED,
    GET_SUB_CLIENTS_SUCCEEDED,
    GET_EXISTING_MATTERS_SUCCEEDED,
    UPDATE_EDITED_FIELD,
    PRE_POPULATE_REQUIRED_FIELDS,
    SUBMIT_HANDLE,
    SET_WIDGET_DATA_SUCCESS,
    SET_CM_INQUIRY_LOADING,
    SET_DISPLAY_WIDGET_SECTION,
} from "../../constants/ClientMatterInquiry/balances";

export const setCMInquiryLoading = (isLoading) => ({
    type: SET_CM_INQUIRY_LOADING,
    isLoading,
});

export const updateEditedField = (fieldName, updatedValue) => ({
    type: UPDATE_EDITED_FIELD,
    fieldName,
    updatedValue,
});

export const getExistingClientsSucceeded = (data) => ({
    type: GET_EXISTING_CLIENTS_SUCCEEDED,
    data,
});

export const getSubClientsSucceeded = (data) => ({
    type: GET_SUB_CLIENTS_SUCCEEDED,
    data,
});

export const getExistingMattersSucceeded = (data) => ({
    type: GET_EXISTING_MATTERS_SUCCEEDED,
    data,
});

export const prePopluateRequiredFields = (clientId) => ({
    type: PRE_POPULATE_REQUIRED_FIELDS,
    clientId,
});

export const submitHandler = () => ({
    type: SUBMIT_HANDLE,
});

export const setWidgetDataSuccess = (data) => ({
    type: SET_WIDGET_DATA_SUCCESS,
    data,
});

export const setDisplayWidgetSection = (isDisplay) => ({
    type: SET_DISPLAY_WIDGET_SECTION,
    isDisplay,
});