import { useState, useEffect } from "react";
export default class {
  subscriptions = [];
  clearTimeOut = null;
  subScribe = (func, label) => {
    let subscription = this.subscriptions.find((d) => d.label === label);
    if (!subscription) {
      this.subscriptions.push({ label: label, action: func });
    }
  };
  update = (method, props = [], throttle = 100) => {
    // all changes of state go through this function:
    if (method && typeof method === "function") {
      //let label = Object.keys(this).findIndex((key) => this[key] === method);
      //console.log(Object.keys(this)[label], props, this);
      method(...props);
      this._onUpdate && this._onUpdate();
      this.stateChanged(throttle);
    }
  };
  unSubscribe = (label) => {
    this.subscriptions = this.subscriptions.filter((d) => d.label !== label);
  };
  stateChanged = (throttle) => {
    if (!this.clearTimeOut) {
      this.clearTimeOut = window.setTimeout(() => {
        this.subscriptions.forEach((d) => d.action && d.action());
        this.clearTimeOut = null;
      }, throttle);
    }
  };
}

export const getRandom = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789";

  for (var i = 0; i < length; i++) {
    result += characters.charAt(
      Math.round(jsUtils.getRandomNumber() * 0.00000001, 2)
    );
  }
  return result;
};

export const useSubscribe = (props) => {
  const [refresh, setRefresh] = useState(0);
  const [uniqueCode] = useState(getRandom(8));
  useEffect(() => {
    props.forEach((item) => {
      item.subScribe(() => setRefresh(refresh + 1), uniqueCode);
    });
    return function () {
      // clean up
      props.forEach((item) => item.unSubscribe(uniqueCode));
    };
  }, [refresh]);
};

export const subscribeLit = (props, requestUpdate) => {
  //const [refresh, setRefresh] = useState(0);
  const uniqueCode = getRandom(8);
  //useEffect(() => {
  props.forEach((item) => {
    item.subScribe(() => requestUpdate(), uniqueCode);
  });
  return function () {
    // clean up
    props.forEach((item) => item.unSubscribe(uniqueCode));
  };
  //}, [refresh]);
};

export class Action {
  name = "";
  constructor(name) {
    this.name = name;
  }
  subscriptions = [];
  subscribe = (func, label) => {
    if (func && typeof func === "function") {
      if (!label) {
        label = getRandom(10);
      }
      let item = this.subscriptions.find((d) => d.label === label);
      if (!item) {
        this.subscriptions.push({ label, action: func });
      }
      return () => {
        this.unSubscribe(label);
      };
    }
    return new Error("function needs to be passed as first parameter");
  };
  unSubscribe = (label) => {
    this.subscriptions = this.subscriptions.filter((d) => d.label !== label);
  };
  run = (...params) => {
    this.subscriptions.forEach((d) => d.action(...params));
  };
}
