import { SET_AUTH_DETAILS, RESET_AUTH_DETAILS } from "./ActionType";

export const setAuthDetails = (cognitoUser, email) => {
  return {
    type: SET_AUTH_DETAILS,
    data: { user: cognitoUser, email: email },
  };
};
export const resetAuthDetails = () => {
  return {
    type: RESET_AUTH_DETAILS,
  };
};
