import { takeLatest, put, select } from "redux-saga/effects";
import {
  DOWNLOAD_ATTACHMENT, FETCH_USER_PREFERANCE_DETAILS, GET_CM_LITERALS, SET_BREADCRUMB_DATA,
} from "../constants/appAction";
import { conflictscreenApi } from "../services/conflictscreenApi";
import storage from "../../utils/storage";
import { setTabIemsData } from "../actions/commonAction";
import { userApi } from "../services/userapis";
import { fetchUserPreferenceDetailsSucceeded } from "../actions/AppAction";
import { conflictSearchApi } from "../services/conflictSearchApi";
import { setSelectedTimeKeeperFeature } from "../actions/TimeManagementAction";
import { SET_DELEGATE_FEATURE } from "../actions/ActionType";
import { GetSetMeData } from "@attorneyflow-mf/styleguide";

export function* getConflictManagementLiterals() {
  try {
    const literalsFromStorage = storage.getObject("cm_literals");
    if (!literalsFromStorage) {
      const response = yield conflictscreenApi.getCMLiterals();
      if (response.success) {
        storage.setObject("cm_literals", response?.literals, true);
      } else {
        console.log("Error! While fetching the Conflict Management Literals");
      }
    }
  } catch (e) {
    console.log("Error! While fetching the Conflict Management Literals");
  }
}

export function* setBreadCrumbandTabMenuItems({ data }) {
  try {
    const { items } = yield select((state) => state.LandingPageReducer);
    let finalMenuList = [];
    const getMenuItems = items.moduleGroups
      .find((x) => x.moduleGroupName === data[0])
      ?.modules.find((y) => y.moduleName === data[1])?.menus;
    if (getMenuItems) {
      getMenuItems.forEach((menuRecord) => {
        finalMenuList.push({
          id: menuRecord.menuId,
          path: menuRecord.url,
          checkpath: items.moduleGroups
            .find((x) => x.moduleGroupName === data[0])
            ?.modules.find((y) => y.moduleName === data[1])?.url,
          label: menuRecord.menuName,
        });
      });
    }
    yield put(setTabIemsData(data, finalMenuList));
  } catch (e) {
    console.log(e);
  }
}

export function* getUserPreferanceDetails() {
  try {
    const cachedMeData = GetSetMeData();
    if (!cachedMeData) {
      const response = yield userApi.getUserDetails();
      if (response.success) {
        GetSetMeData(response);
        yield put(fetchUserPreferenceDetailsSucceeded(response));
      }
    } else {
      yield put(fetchUserPreferenceDetailsSucceeded(cachedMeData));
    }
  } catch (e) { }
}

export function* downloadAttachment(action) {
  try {
    yield put(action._loadingAction(true));
    const response = yield conflictSearchApi.getDownloadURL(
      action.id,
      action.s3Folder
    );
    if (response.success && response.signed_url) {
      window.downloadAttachment(response.signed_url);
    }
    yield put(action._loadingAction(false));
  } catch (e) {
    yield put(action._loadingAction(false));
  }
}

export function* setDelegateFeatureDetails({ moduleName, featureName }) {
  try {
    const { items } = yield select((state) => state.LandingPageReducer);
    let feature;
    items?.moduleGroups?.forEach((groupObj) => {
      groupObj.modules.forEach((moduleObj) => {
        if (moduleObj.moduleName === moduleName) {
          moduleObj.menus?.forEach((menu) => {
            menu?.features?.some(function (menuFeature) {
              if (menuFeature.featureName === featureName) {
                feature = menuFeature;
                return feature;
              }
            });
          });
        }
      });
    });
    if (feature) {
      yield put(setSelectedTimeKeeperFeature(featureName, feature?.featureId));
    } else {
      console.log(
        "FeatureId is mapped incorrectly for the feature " + featureName
      );
    }
  } catch (e) { }
}

export function* appSaga() {
  yield takeLatest(GET_CM_LITERALS, getConflictManagementLiterals);
  yield takeLatest(SET_BREADCRUMB_DATA, setBreadCrumbandTabMenuItems);
  yield takeLatest(FETCH_USER_PREFERANCE_DETAILS, getUserPreferanceDetails);
  yield takeLatest(DOWNLOAD_ATTACHMENT, downloadAttachment);
  yield takeLatest(SET_DELEGATE_FEATURE, setDelegateFeatureDetails);
}

export default appSaga;
